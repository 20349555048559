import React from 'react';
import { Route, useHistory } from "react-router-dom";
import { Authorization } from './services/authorization';


interface AuthProps {
  path: string;
}

const AuthRoute: React.FC<AuthProps> = (props) => {
  const history = useHistory();
  
  if (!Authorization.GetUsername()) {
    history.push("/login");
  }

  return <Route path={props.path}>
    {props.children}
  </Route>
}

export default AuthRoute;
