import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckOut from '../CheckOut/CheckOut';
import { STRIPE_PK } from '../../config';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PK);


interface PaymentProps {
  next: () => void;
};

const Payment = (props: PaymentProps) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckOut next={props.next} />
    </Elements>
  )
};

export default Payment;
