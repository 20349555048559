import { FormControl } from '@material-ui/core';

import ToogleButton from '../ToggleButton/ToggleButton';

export enum LifeTimeUpdates {
  Yes = 1,
  No = 0,
  Unset = -1
}

interface LifeTimeUpdateProps {
  lifeTimeUpdates: LifeTimeUpdates;
  setLifeTimeUpdates(lifeTimeUpdates: LifeTimeUpdates): void;
};

const LifeTimeUpdate = (props: LifeTimeUpdateProps) => {
  return (
    <FormControl>
      <div className="text-box margin-bottom-r6 ft-22">
        <label className="label-r5">
          <b>Subscribe to Lifetime Updates</b> protect your wishes with free access to updates, certificates and your Capacity Vault evidence
        </label>
      </div>
      <ToogleButton
        firstValue={LifeTimeUpdates.Yes} secondValue={LifeTimeUpdates.No} currentValue={props.lifeTimeUpdates}
        update={isSubscriber => props.setLifeTimeUpdates(isSubscriber)}
      />
    </FormControl>
  )
};

export default LifeTimeUpdate;
