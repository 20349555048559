import { forwardRef, useImperativeHandle } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import './UpdateCreditCardElement.scss';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import { Authorization } from '../../services/authorization';

const UpdateCreditCardElement = (props: any, ref: any) => {

  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async update() {
      return await handleCardChange();
    }
  }));

  const handleCardChange = async () => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createToken(elements.getElement(CardElement) as StripeCardElement);

    var intent: any = await axios.post(SERVER_URL + "/payment/updateCreditCard", {
      cardToken: result?.token?.id
    }, {
      headers: {
        Authorization: await Authorization.AuthToken()
      }
    });

    if (intent.status === 200) {
      return true;
    }
    return;
  }

  return (
    <form className="SubscriptoinCheckOut CheckOut" onSubmit={handleCardChange}>
      <div className="card-box">
        <CardElement options={{ hidePostalCode: true }} />
      </div>
    </form>
  );
}

export default forwardRef(UpdateCreditCardElement);
