const checkInArray = (elem: any, array: any) => {
  if (array.indexOf) {
    return array.indexOf(elem);
  }

  for (var i = 0, length = array.length; i < length; i++) {
    if (array[i] === elem) {
      return i;
    }
  }

  return -1;
}

export const validateEnglandWalesPostcode = (value: any) => {
  var contents = value;
  if (value) {
    var parts = contents.split(" ");
    if (parts.length === 2) {
      var validCodes = (window as any).englandWalesOutcodes;
      var outcode = parts[0].replace(/[0-9]/g, "");
      var inArray = checkInArray(outcode, validCodes);
      if (inArray !== -1) {
        var matchRes = contents.match(/^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/g);
        return matchRes !== null;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};