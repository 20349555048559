import { Button } from '@material-ui/core';
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import UpdatePopup from '../UserDetails/UpdatePopup';
import UserPassword from '../UserPassword/UserPassword';

const UpdateUserPassword: React.FC = () => {
  const history = useHistory();
  const [success, setSuccess] = React.useState(false);
  const [user] = React.useState({});
  const userPasswordRef = useRef(null);
  const [nextButtonDisabledState, setNextButtonDisabledState] = React.useState(false);
  
  const changePassword = async () => {
    if (userPasswordRef && userPasswordRef.current) {
      const current = userPasswordRef?.current as any;
      const res = await current.next();
      console.log(res);
      setSuccess(true);
    }
  }

  return (
    <div className="UserDetails UpdateUserPassword">
      <UserPassword user={user} setNextButtonDisabledState={setNextButtonDisabledState} ref={userPasswordRef} updatePassword={true} />
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Back
      </Button>
      <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
        className="save-button"
        variant="contained"
        color="primary"
        onClick={changePassword}
        disabled={nextButtonDisabledState}
      >
        Save
      </Button>
      <UpdatePopup open={success} close={() => {
        setSuccess(false);
        history.push('/userAccount');
      }} />
    </div>
  )
}

export default UpdateUserPassword;
