import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface MainStepState {
  step: number;
}

const initialState: MainStepState = {
  step: 0,
};

export const mainStepSlice = createSlice({
  name: 'mainStep',
  initialState,
  reducers: {
    setStep: (state, d) => {
      state.step = d.payload;
    },
  }
});

export const { setStep } = mainStepSlice.actions;

export const selectMainStep = (state: RootState) => state.mainStep.step;

export default mainStepSlice.reducer;
