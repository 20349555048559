import Button from '@material-ui/core/Button';
import React, { forwardRef, useContext, useState } from 'react';
import { isIOS, isIOS13, isMobileSafari } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { isPhone } from '../../services/deviceService';
import WebcamStreamCapture from '../WebcamStreamCapture/WebcamStreamCapture';
import './Demo.scss';
import TenantContext from '../../contexts/tenantContext';

const subtitles = [
  'What is your full name?',
  'Please state your date of birth',
  `What's your favourite song?`,
];

const Demo = () => {
  const [prevStep, setPrevStep] = useState(0);
  const [isRecordingStarted, setRecordingStarted] = useState(false);
  const [isPlaying, setPlay] = useState(false);
  const [step, setStep] = useState(0);
  const [cameraReady, setCameraReady] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  const tenant = useContext(TenantContext);

  const [internalPlayer, setPlayer] = useState(null as any);
  var [source, setSource] = useState(null as any);
  const [lastUrl, setLastUrl] = useState("");

  const [audioContext, setAudioContext] = useState(null as any);

  const playerRef = (player: any) => {
    if (player && player.player.props.url !== lastUrl) {
      setLastUrl(player.player.props.url);
      setPlayer(player);
    }
  }

  // Safari sound fix.
  const mixAudio = () => {
    if (isMobileSafari || isIOS || isIOS13) {
      if (internalPlayer != null) {
        if (!source) {

          var context = new AudioContext()
          setAudioContext(context);
          var player = internalPlayer!.getInternalPlayer();

          var alexSource = context.createMediaElementSource(player);
          setSource(alexSource);
          var gain = context.createGain();

          gain.gain.value = 8;

          alexSource.connect(gain);
          gain.connect(context.destination);

        }
      }
    }
  }

  /* landscape detection */
  const isHorizontal = () => {
    // Apple does not seem to have the window.screen api so we have to use deprecated window.orientation instead.
    if (window.orientation && typeof window.orientation === "number" && Math.abs(window.orientation) === 90) {
      return true;
    }
    if (window.screen.orientation && window.screen.orientation.type.includes('/^landscape-.+$/') === true) {
      return true;
    }
    return false;
  };

  const [horizontal, setHorizontal] = React.useState(isHorizontal());

  React.useEffect(() => {
    window.addEventListener("orientationchange", (event: any) => {
      setHorizontal(isHorizontal());
    });
  }, []);
  /* landscape detection */

  const next = async (restart?: boolean) => {
    if (restart) {
      setPrevStep(1);
      setStep(0);
      return false;
    }

    if (prevStep === 0) {
      setPrevStep(1);
      return false;
    }
    if (prevStep === 1) {
      setNextButtonDisabled(true);
      setPlay(true);
      setPrevStep(2);
      return false;
    }
    if (step < subtitles.length - 1) {
      setNextButtonDisabled(true);
      setPlay(true);
      setStep(step + 1);
      return false;
    } else if (step === subtitles.length - 1) {
      setRecordingStarted(false);
      setStep(step + 1);
      if (audioContext) {
        audioContext.close();
        return false;
      }
    } else {
      return true;
    }
  }

  const renderRecordDescription = () => {
    if (isRecordingStarted && prevStep === 2) {
      return (
        <div>
          <div className={`test-audio-description ${!isPlaying ? "invisible-alex" : "alex-video-shadow"}`}>
            <ReactPlayer
              ref={playerRef}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous"
                  }
                }
              }}
              playing={isPlaying}
              volume={1.0}
              playsinline
              url={tenant.screenChecks[step].videoUrl}
              onProgress={() => {
                (cameraReady === false) && setCameraReady(true)
              }}
              onReady={() => mixAudio()}
              onEnded={() => { setNextButtonDisabled(false); setPlay(false) }}
            />
          </div>
          {cameraReady && isPlaying &&
            <div className="check-screen-subtitle">
              <span>{subtitles[step]}</span>
            </div>}
        </div>
      );
    }
    return <div />;
  }

  let className = "Demo";

  if (isPhone() && horizontal) {
    className += ' Horizontal';
  }

  if (prevStep === 0) {
    return (
      <div className="Demo">
        <div className="message-content ft-35">
          <Button
            variant="contained"
            style={{ top: "10vh" }}
            className="green-submit-button video-button"
            onClick={async () => await next()}>
            Start Demo
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={className} data-testid="Demo">
      {step === 3 
        ?
          <div className="Demo">
            <div className="message-content ft-35">
              <div>
                <a href="https://capacityvault.co.uk/for-professionals" target='_top'>learn more</a>
              </div>
              <br></br>
              <div className="message-content ft-35">
              <Button
                variant="contained"
                style={{ top: "10vh" }}
                className="green-submit-button video-button"
                onClick={async () => await next(true)}>
                Start Demo
              </Button>
            </div>
            </div>
          </div>
        :
        <div>
          <div id="circle"><div></div></div>
          {renderRecordDescription()}
          <WebcamStreamCapture
            isDemo={true}
            screenCheck={true}
            onMouted={() => setRecordingStarted(true)}
            saveVideoRecording={false}
            isRecordingStarted={isRecordingStarted}
            onRecordingStop={(videoBlob) => {
              setStep(3);
            }} />

        <Button
          variant="contained"
          onClick={async () => await next()}
          style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
          className="green-submit-button video-button"
          disabled={nextButtonDisabled}
        >
          {step === subtitles.length - 1 ? 'Finish' : 'Next'}
        </Button>
        </div>
        }
       
    </div>
  );
}

export default forwardRef(Demo);
