import React from 'react';
import { LANDING_URL } from '../../config';
import './Footer.scss';

const Footer:React.FC = () => {
  const domain = LANDING_URL;

  return (
    <div className="Footer" data-testid="Footer">
      <div className="footer-inside">
        <div className="first-container">
          <div className="first-column">
            <div className="logo-container">
              <img src="/logo-wide.png" alt="logo-footer" />
            </div>
            <p className="l-text l-first">Trusted by financial services professionals since 2008.</p>
            <p className="l-text">Registered in England and Wales<br />
              Company No. 7613710<br />
              All Rights Reserved.</p>
          </div>
          <div className="second-column">
            <a href={domain + '/how-it-works'}>► How It Works</a>
            <a href={domain + '/testamentary-capacity'}>► Testamentary capacity</a>
            <a href={domain + '/about-us'}>► About us</a>
            <a href={domain + '/data-policy'}>► Data policy</a>
            <a href={domain + '/about-us/#capacity-vault-contact-form'}>► Contact us</a>
          </div>
        </div>
        <div className="separator">
          <hr />
        </div>
        <div className="last-text">
          This material is for general information only and does not constitute tax, legal or any other form of advice. You should not rely on any information contained herein to make (or refrain from making) any decisions. Always obtain independent, professional advice for your own particular situation. Capacity Vault is a member of the Society of Will Writers. It is not regulated by the Solicitor's Regulation Authority. Solicitors employed by Capacity Vault are able to act for Capacity Vault's customers in respect of unreserved legal activities including drafting and checking documents and providing advice on unreserved matters. In the event of a conflict of interest the solicitor will cease to act.
        </div>
      </div>
    </div>
  );
}

export default Footer;
