import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Affiliate } from '../../services/axios/requests';

export interface AffiliateState {
  affiliate: Affiliate;
}

const initialState: AffiliateState = {
  affiliate: JSON.parse(localStorage.getItem('affiliate') ?? "null") 
};

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    saveAffiliate: (state, d: PayloadAction<Affiliate>) => {
      console.log(`Affiliate dispatched: ${JSON.stringify(d.payload)}`);
      localStorage.setItem('affiliate', JSON.stringify(d.payload));
      state.affiliate = d.payload;
    },
  }
});

export const { saveAffiliate } = affiliateSlice.actions;

export const selectAffiliate = (state: RootState) => state.affiliate.affiliate;

export default affiliateSlice.reducer;
