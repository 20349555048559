import './App.scss';
import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ReactGA from "react-ga4";
import axios from 'axios';
import { useCookies } from 'react-cookie';

import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';

import MainSteps from './components/MainSteps/MainSteps';
import Login from './components/Login/Login';
import UserAccount from './components/UserAccount/UserAccount';
import DownloadCertificate from './components/DownloadCertificate/DownloadCertificate';
import UpdateDetails from './components/UpdateDetails/UpdateDetails';
import UpdatePaymentDetails from './components/UpdatePaymentDetails/UpdatePaymentDetails';
import UpdateUserPassword from './components/UpdateUserPassword/UpdateUserPassword';
import UpdateCreditCard from './components/UpdateCreditCard/UpdateCreditCard';
import CancelSubscription from './components/CancelSubscription/CancelSubscription';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ForgotPasswordSet from './components/ForgotPasswordSet/ForgotPasswordSet';
import SetPassword from './components/SetPassword/SetPassword';
import AffiliateLanding from './components/AffiliateLanding/AffiliateLanding';
import RecordingToken from './components/RecordingToken/RecordingToken';
import Demo from './components/Demo/Demo';

import './App.scss';
import { isPhone } from './services/deviceService';
import AuthRoute from './AuthRoute';
import { SERVER_URL } from './config';
import { useAppDispatch } from './app/hooks';
import { saveAffiliate } from './features/affiliate/affiliateSlice';
import { Authorization } from './services/authorization';
import TenantContext, { Tenant } from './contexts/tenantContext';
import { getAffiliateById, getCurrentUser } from './services/axios/requests';


function App() {
  const location = useLocation();
  const [tenant, setTenant] = React.useState({} as Tenant)
  const [cookies, setCookie] = useCookies(['tenant']);

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.addEventListener("message", (event: any) => {

      if (!event.origin.includes('makeawillonline.co.uk')) {
        console.log('Ivalid message origin');
        return;
      }

      sessionStorage.setItem("iframe-name", event.data.name);
      sessionStorage.setItem("iframe-dateOfBirth", event.data.dateOfBirth ?? "");
      sessionStorage.setItem("iframe-email", event.data.email ?? "");
      sessionStorage.setItem("iframe-address1", event.data.address1 ?? "");
      sessionStorage.setItem("iframe-address2", event.data.address2 ?? "");
      sessionStorage.setItem("iframe-town", event.data.town ?? ""); 
      sessionStorage.setItem("iframe-postcode", event.data.postcode ?? "");
      sessionStorage.setItem("iframe-county", event.data.county ?? "");
    });
  }, [])
  

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
 
  const getQuery = () => {
    return new URLSearchParams(location.search);
  }

  const isHorizontal = () => {
    // Apple does not seem to have the window.screen api so we have to use deprecated window.orientation instead.
    if (window.orientation && typeof window.orientation === "number" && Math.abs(window.orientation) === 90) {
      return true;
    }
    if (window.screen.orientation && window.screen.orientation.type.includes('/^landscape-.+$/') === true) {
      return true;
    }
    return false;
  };
  const [horizontal, setHorizontal] = React.useState(isHorizontal());

  const getTenantUrl = () => {

    let query = getQuery();
    var tenantUrl = query.get("tenant");
    
    console.log('tenantUrl', tenantUrl);
    console.log('coockie', cookies);

    if (tenantUrl && tenantUrl !== ""){
      setCookie("tenant", tenantUrl);
    } else if (cookies.tenant){
      tenantUrl = cookies.tenant;
    } else {
      tenantUrl = "default";
      setCookie("tenant", tenantUrl);
    }

    return tenantUrl;
  }

  const getTenant = async () => {
    try {
      var tenantUrl = getTenantUrl();

      console.log('get tenant tenantUrl', tenantUrl);

      const response = await axios.get(SERVER_URL + `/tenant?tenantUrl=${tenantUrl}`);
      
      if (response && response.status === 200) {
        setTenant(response.data)
      } else {
        console.log('can`t get tenant');
      }
    } catch {
      console.log('can`t get tenant');
    }
  }

  useEffect(() => {
    getTenant()
    window.addEventListener("orientationchange", () => {
      setHorizontal(isHorizontal());
    });
    // eslint-disable-next-line
  }, [location]);

  const [signedIn, setSignedIn] = useState(false);

  if (Authorization.GetUsername()) {
    if (!signedIn)
      setSignedIn(true);
  }

  useEffect(() => {
    if (signedIn){
      setAffiliate();
    }
    // eslint-disable-next-line
  }, [signedIn]);

  const setAffiliate = async () => {
    try {
      const response = await getCurrentUser();

      if (response?.data?.Item?.affiliateId) {
        const affiliate = await getAffiliateById(response.data.Item.affiliateId);

        dispatch(saveAffiliate(affiliate.data));
      }
    } catch(e) {
      console.log('affiliate error');
      console.error(e);
    }
  }

  return (
    <div className="App">
      {tenant?.tenantId && <TenantContext.Provider value={tenant}>
        <div style={{ minHeight: '100vh' }}>
          <Switch>
            <Route exact path="/">
              <Header signedIn={signedIn} />
              <MainSteps />
            </Route>
            <Route exact path="/demo">
              <Demo />
            </Route>
            <Route path="/login">
              <Header signedIn={signedIn} />
              <Login />
            </Route>
            <Route path="/affiliate/:url">
              <Header signedIn={signedIn} />
              <AffiliateLanding />
            </Route>
            <Route path="/affiliateFrame/:affiliateId">
              <Header signedIn={signedIn} />
              <AffiliateLanding />
            </Route>
            <Route path="/confirmPassword">
              <Header signedIn={signedIn} />
              <ForgotPasswordSet />
            </Route>
            <AuthRoute path="/userAccount">
              <Header signedIn={signedIn} />
              <UserAccount />
            </AuthRoute>
            <AuthRoute path="/downloadCertificate">
              <Header signedIn={signedIn} />
              <DownloadCertificate />
            </AuthRoute>
            <AuthRoute path="/updateDetails">
              <Header signedIn={signedIn} />
              <UpdateDetails />
            </AuthRoute>
            <AuthRoute path="/updatePaymentDetails">
              <Header signedIn={signedIn} />
              <UpdatePaymentDetails />
            </AuthRoute>
            <Route path="/resetPassword">
              <Header signedIn={signedIn} />
              <UpdateUserPassword />
            </Route>
            <AuthRoute path="/UpdateCreditCard">
              <Header signedIn={signedIn} />
              <UpdateCreditCard />
            </AuthRoute>
            <AuthRoute path="/cancelSubscription">
              <Header signedIn={signedIn} />
              <CancelSubscription />
            </AuthRoute>
            <Route path="/forgotPassword">
              <Header signedIn={signedIn} />
              <ForgotPassword />
            </Route>
            <Route path="/setPassword">
              <Header signedIn={signedIn} />
              <SetPassword />
            </Route>
            <Route path="/recording-token/:token">
              <Header signedIn={signedIn} />
              <RecordingToken />
            </Route>
          </Switch>
        </div>
        {isPhone() && horizontal && <Dialog className="RotatePopup" open={true}>
          <DialogTitle className="ft-25">
            Please rotate device to continue
          </DialogTitle>
        </Dialog>}
        {!location.pathname.includes("/demo") &&
          <Footer />
        }
        </TenantContext.Provider>
    }
    </div>
  );
}

export default App;
