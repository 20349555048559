import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';

import './UserAccount.scss';
import { isPhone } from '../../services/deviceService';
import { getCurrentUser } from '../../services/axios/requests';

const UserAccount: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = React.useState(null as any);

  const getUser = async () => {
    try {
      const response = await getCurrentUser();
      
      if (response && response.status === 200) {
        setUser(response.data.Item)
      }
    } catch {
      console.log('can`t get current user');
    }
  }

  React.useEffect(() => {
    getUser();
  }, []);

  if (isPhone()) {
    return (
      <div className="UserAccount" data-testid="UserAccount">
        <div>
          <div className="upper-container">
            <div className="ft-25 bold title">Hello {user ? user.name : ''}</div>
            <div className="ft-22">Welcome to your Capacity Vault. On this page you can create a new Capacity Vault recording and manage your account and payment options.</div>
            <div className="ft-25 bold second-title">How can we help you today?</div>
          </div>
          <div className="cards-content">
            <Card id="btn-new-will-recording" variant="outlined" className="card" onClick={() => history.push('/?newRecording=true&tenant=default')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Create new</p>
                    <p>recording when</p>
                    <p>you update your will</p>
                  </div>
                </div>
                <div className="btn-container"><AddIcon /></div>
              </CardContent>
            </Card>
            <Card id="btn-new-lpa-recording" variant="outlined" className="card" onClick={() => history.push('/?newRecording=true&tenant=LPA')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Create new</p>
                    <p>recording</p>
                    <p>for LPA</p>
                  </div>
                </div>
                <div className="btn-container"><AddIcon /></div>
              </CardContent>
            </Card>
          </div>
          <div className="cards-content">
          <Card id="btn-download-certificates" variant="outlined" className="card" onClick={() => history.push('/downloadCertificate')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Download certificate</p>
                    <p>of a recording</p>
                  </div>
                </div>
                <div className="btn-container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59"><rect fill="none" width="59" height="59" /><g transform="translate(11.833 11.833)"><path d="M34.917,28.292v6.625H8.417V28.292H4v6.625a4.43,4.43,0,0,0,4.417,4.417h26.5a4.43,4.43,0,0,0,4.417-4.417V28.292Zm-2.208-8.833-3.114-3.114-5.72,5.7V4H19.458V22.042l-5.72-5.7-3.114,3.114L21.667,30.5Z" transform="translate(-4 -4)" /></g></svg>
                </div>
              </CardContent>
            </Card>
            <Card id="btn-update-email" variant="outlined" className="card" onClick={() => history.push('/updateDetails')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Update your email or</p>
                    <p>contact details</p>
                  </div>
                </div>
                <div className="btn-container">
                  <svg xmlns="http://www.w3.org/2000/svg" className="manage-user-icon" viewBox="0 0 43.199 43.199"><path fill="none" d="M0,0H43.2V43.2H0Z" /><g transform="translate(3.6 7.2)"><path className="b" d="M5.6,22V20.83a1.7,1.7,0,0,1,.738-1.458A19.6,19.6,0,0,1,16.4,16.6a.4.4,0,0,1,.144.018,12.394,12.394,0,0,1,1.062-3.564c-.4-.036-.792-.054-1.206-.054A23.176,23.176,0,0,0,4.5,16.276,5.26,5.26,0,0,0,2,20.83V25.6H18.668A12.838,12.838,0,0,1,16.922,22Z" transform="translate(-2 3.2)" /><path className="b" d="M13.2,18.4A7.2,7.2,0,1,0,6,11.2,7.2,7.2,0,0,0,13.2,18.4Zm0-10.8a3.6,3.6,0,1,1-3.6,3.6A3.61,3.61,0,0,1,13.2,7.6Z" transform="translate(1.2 -4)" /><path className="b" d="M27.614,20a7.809,7.809,0,0,0-.108-1.134l2.052-1.818-1.8-3.114-2.61.882A6.564,6.564,0,0,0,23.2,13.682L22.664,11h-3.6l-.54,2.682a6.564,6.564,0,0,0-1.944,1.134l-2.61-.882-1.8,3.114,2.052,1.818A7.808,7.808,0,0,0,14.114,20a7.808,7.808,0,0,0,.108,1.134L12.17,22.952l1.8,3.114,2.61-.882a6.564,6.564,0,0,0,1.944,1.134L19.064,29h3.6l.54-2.682a6.564,6.564,0,0,0,1.944-1.134l2.61.882,1.8-3.114-2.052-1.818A7.809,7.809,0,0,0,27.614,20Zm-6.75,3.6a3.6,3.6,0,1,1,3.6-3.6A3.61,3.61,0,0,1,20.864,23.6Z" transform="translate(6.135 1.6)" /></g></svg>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="cards-content">
          <Card variant="outlined" className="card" onClick={() => history.push('/updatePaymentDetails')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Update your payment</p>
                    <p>details or cancel your</p>
                    <p>subscription</p>
                  </div>
                </div>
                <div className="btn-container"><CreditCardIcon /></div>
              </CardContent>
            </Card>
            <Card id="btn-change-password" variant="outlined" className="card" onClick={() => history.push('/resetPassword')}>
              <CardContent className="ft-22">
                <div className="card-text-block">
                  <div>
                    <p>Change password</p>
                  </div>
                </div>
                <div className="btn-container">
                  <svg xmlns="http://www.w3.org/2000/svg" className="password-icon" viewBox="0 0 36.208 19.75"><path d="M2.646,23.458H35.563V26.75H2.646Zm1.893-6.666,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8V10.654h-2.8l1.4-2.419L7.336,7l-1.4,2.419L4.539,7,2.4,8.234l1.4,2.419H1v2.469H3.8L2.4,15.558Zm11.027-1.234,2.14,1.234,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8V10.654h-2.8l1.4-2.419L20.5,7,19.1,9.419,17.705,7l-2.14,1.234,1.4,2.419h-2.8v2.469h2.8Zm21.643-4.9h-2.8l1.4-2.419L33.67,7l-1.4,2.419L30.872,7l-2.14,1.234,1.4,2.419h-2.8v2.469h2.8l-1.4,2.436,2.14,1.234,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8Z" transform="translate(-1 -7)" /></svg>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="UserAccount" data-testid="UserAccount">
      <div>
        <div className="upper-container">
          <div className="ft-25 bold title">Hello {user ? user.name : ''}</div>
          <div className="ft-22">Welcome to your Capacity Vault. On this page you can create a new Capacity Vault recording and manage your account and payment options.</div>
          <div className="ft-25 bold second-title">How can we help you today?</div>
        </div>
        <div className="cards-content">
          <Card id="btn-new-will-recording" variant="outlined" className="card" onClick={() => history.push('/?newRecording=true&tenant=default')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Create new</p>
                  <p>recording when</p>
                  <p>you update your will</p>
                </div>
              </div>
              <div className="btn-container"><AddIcon /></div>
            </CardContent>
          </Card>
          <Card id="btn-new-lpa-recording" variant="outlined" className="card" onClick={() => history.push('/?newRecording=true&tenant=LPA')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Create new</p>
                  <p>recording</p>
                  <p>for LPA</p>
                </div>
              </div>
              <div className="btn-container"><AddIcon /></div>
            </CardContent>
          </Card>
          <Card variant="outlined" className="card" onClick={() => history.push('/downloadCertificate')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Download certificate</p>
                  <p>of a recording</p>
                </div>
              </div>
              <div className="btn-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 59 59"><rect fill="none" width="59" height="59" /><g transform="translate(11.833 11.833)"><path d="M34.917,28.292v6.625H8.417V28.292H4v6.625a4.43,4.43,0,0,0,4.417,4.417h26.5a4.43,4.43,0,0,0,4.417-4.417V28.292Zm-2.208-8.833-3.114-3.114-5.72,5.7V4H19.458V22.042l-5.72-5.7-3.114,3.114L21.667,30.5Z" transform="translate(-4 -4)" /></g></svg>
              </div>
            </CardContent>
          </Card>
          
        </div>
        <div className="cards-content">
          <Card variant="outlined" className="card" onClick={() => history.push('/updateDetails')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Update your email or</p>
                  <p>contact details</p>
                </div>
              </div>
              <div className="btn-container">
                <svg xmlns="http://www.w3.org/2000/svg" className="manage-user-icon" viewBox="0 0 43.199 43.199"><path fill="none" d="M0,0H43.2V43.2H0Z" /><g transform="translate(3.6 7.2)"><path className="b" d="M5.6,22V20.83a1.7,1.7,0,0,1,.738-1.458A19.6,19.6,0,0,1,16.4,16.6a.4.4,0,0,1,.144.018,12.394,12.394,0,0,1,1.062-3.564c-.4-.036-.792-.054-1.206-.054A23.176,23.176,0,0,0,4.5,16.276,5.26,5.26,0,0,0,2,20.83V25.6H18.668A12.838,12.838,0,0,1,16.922,22Z" transform="translate(-2 3.2)" /><path className="b" d="M13.2,18.4A7.2,7.2,0,1,0,6,11.2,7.2,7.2,0,0,0,13.2,18.4Zm0-10.8a3.6,3.6,0,1,1-3.6,3.6A3.61,3.61,0,0,1,13.2,7.6Z" transform="translate(1.2 -4)" /><path className="b" d="M27.614,20a7.809,7.809,0,0,0-.108-1.134l2.052-1.818-1.8-3.114-2.61.882A6.564,6.564,0,0,0,23.2,13.682L22.664,11h-3.6l-.54,2.682a6.564,6.564,0,0,0-1.944,1.134l-2.61-.882-1.8,3.114,2.052,1.818A7.808,7.808,0,0,0,14.114,20a7.808,7.808,0,0,0,.108,1.134L12.17,22.952l1.8,3.114,2.61-.882a6.564,6.564,0,0,0,1.944,1.134L19.064,29h3.6l.54-2.682a6.564,6.564,0,0,0,1.944-1.134l2.61.882,1.8-3.114-2.052-1.818A7.809,7.809,0,0,0,27.614,20Zm-6.75,3.6a3.6,3.6,0,1,1,3.6-3.6A3.61,3.61,0,0,1,20.864,23.6Z" transform="translate(6.135 1.6)" /></g></svg>
              </div>
            </CardContent>
          </Card>
          <Card variant="outlined" className="card" onClick={() => history.push('/updatePaymentDetails')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Update your payment</p>
                  <p>details or cancel your</p>
                  <p>subscription</p>
                </div>
              </div>
              <div className="btn-container"><CreditCardIcon /></div>
            </CardContent>
          </Card>
          <Card variant="outlined" className="card" onClick={() => history.push('/resetPassword')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Change password</p>
                </div>
              </div>
              <div className="btn-container">
                <svg xmlns="http://www.w3.org/2000/svg" className="password-icon" viewBox="0 0 36.208 19.75"><path d="M2.646,23.458H35.563V26.75H2.646Zm1.893-6.666,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8V10.654h-2.8l1.4-2.419L7.336,7l-1.4,2.419L4.539,7,2.4,8.234l1.4,2.419H1v2.469H3.8L2.4,15.558Zm11.027-1.234,2.14,1.234,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8V10.654h-2.8l1.4-2.419L20.5,7,19.1,9.419,17.705,7l-2.14,1.234,1.4,2.419h-2.8v2.469h2.8Zm21.643-4.9h-2.8l1.4-2.419L33.67,7l-1.4,2.419L30.872,7l-2.14,1.234,1.4,2.419h-2.8v2.469h2.8l-1.4,2.436,2.14,1.234,1.4-2.436,1.4,2.436,2.14-1.234-1.4-2.436h2.8Z" transform="translate(-1 -7)" /></svg>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default UserAccount;
