import React from 'react';

import './RecSign.scss';

const RecSign: React.FC = () => {
  return <div className="RecSign">
    <div></div>
    Rec
  </div>
}

export default RecSign;
