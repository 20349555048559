import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { CLIENT_ID, USER_POOL_ID } from '../config';

export class Authorization {

    public static async Register(username: string, password: string, email: string) {
        var userPool = this.GetUserPool();

        var attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
            Name: 'email',
            Value: email.toLowerCase(),
        });
        return new Promise((resolve, reject) => {
            userPool.signUp(username.toLowerCase(), password, [attributeEmail], [], (err, result: any) => {
                if (err) {
                    reject(err);
                }
                resolve(result.user);
            });

        });
    }

    public static async ForgotPassword(username: string) {

        const cognitoUser = this.GetUserByUsername(username);

        return new Promise((resolve, reject) => {
            cognitoUser?.forgotPassword({
                onSuccess: async () => {
                    resolve(true);
                },
                onFailure: async (err: any) => {
                    console.log(JSON.stringify(err));
                    reject(err.code);
                }
            })
        })
    }

    public static async ConfirmPassword(username: string, confirmationCode: string, password: string) {

        const cognitoUser = this.GetUserByUsername(username);

        return new Promise((resolve, reject) => {
            cognitoUser?.confirmPassword(confirmationCode, password, {
                onSuccess: async (data: any) => {
                    resolve(true);
                },
                onFailure: (err: any) => {
                    reject(false);
                }
            })
        })
    }


    public static async ChangePassword(oldPassword: string, newPassword: string) {

        var userPool = this.GetUserPool();

        var cognitoUser = userPool.getCurrentUser();

        await this.GetUserSession(cognitoUser);

        return new Promise((resolve, reject) => {
            cognitoUser?.changePassword(oldPassword, newPassword, (err, result) => {
                if (err) {
                    reject(false);
                    return;
                }
                resolve(result);
            });
        });
    }

    public static GetUserByUsername(username: string): AmazonCognitoIdentity.CognitoUser {
        const userPool = this.GetUserPool();

        return new AmazonCognitoIdentity.CognitoUser({
            Username: username,
            Pool: userPool
        });
    }

    public static async SignIn(email: string, password: string): Promise<boolean> {
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: password
        });

        const cognitoUser = this.GetUserByUsername(email);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (session: any, userConfirmationNecessary: any) => {
                    resolve(true);
                },
                onFailure: (err: any) => {
                    reject(err);
                }
            });
        });
    }

    public static async Exists(email: string): Promise<boolean> {
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: email,
            Password: ''
        })

        const cognitoUser = this.GetUserByUsername(email);

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: async (session: any, userConfirmationNecessary: any) => {
                    resolve(true);
                },
                onFailure: (err: any) => {
                    resolve(err.code !== 'UserNotFoundException');
                }
            });
        });
    }

    public static async SignOut(): Promise<boolean> {

        const cognitoUser = this.GetUser();

        return new Promise((resolve) => {
            if (cognitoUser)
                cognitoUser.signOut(() => { resolve(true); });
            else
                resolve(true);
        });
    }


    public static async AuthToken(): Promise<any> {
        const session = await this.GetSession();

        return session
            ? session.getIdToken().getJwtToken()
            : null;
    }

    public static GetUsername(): string | undefined {
        const cognitoUser = this.GetUser()
        return cognitoUser?.getUsername();
    }

    public static GetUserPool(): AmazonCognitoIdentity.CognitoUserPool {
        return new AmazonCognitoIdentity.CognitoUserPool({
            UserPoolId: USER_POOL_ID,
            ClientId: CLIENT_ID,
        });
    }

    public static GetUser(): AmazonCognitoIdentity.CognitoUser | null {
        const userPool = this.GetUserPool()
        return userPool.getCurrentUser();
    }

    public static async GetUserSession(cognitoUser: AmazonCognitoIdentity.CognitoUser | null): Promise<any> {
        return new Promise((resolve, reject) => {
            if (cognitoUser) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        reject(err);
                    } else if (!session.isValid()) {
                        reject(null);
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject(null);
            }
        });
    }

    public static async GetUserAttributes(): Promise<any[]> {
        const cognitoUser = this.GetUser();
        
        await this.GetUserSession(cognitoUser);
        
        return new Promise((resolve, reject) => {
            if (cognitoUser) {
                cognitoUser.getUserAttributes((err: any, attributes: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(attributes);
                    }
                });
            } else {
                reject(null);
            }
        });
    }

    public static async GetUserSubscriptionStatus(): Promise<boolean> {
        try {
            const userAttributes = await Authorization.GetUserAttributes();
            const subscriptionStatus = userAttributes.find(r => r.Name === 'custom:SubscriptionStatus');
            return subscriptionStatus.Value === "1";
        }
        catch (e){
            return false;
        }
    }

    public static async GetSession(): Promise<any> {
        const cognitoUser = this.GetUser();
        return await this.GetUserSession(cognitoUser);
    }

    public static async RefreshToken(): Promise<any> {
        const cognitoUser = this.GetUser();

        if (!cognitoUser)
            return;

        const session = await this.GetUserSession(cognitoUser);

        const refresh_token = session.getRefreshToken();

        return new Promise((resolve, reject) => {
            if (cognitoUser)
                cognitoUser.refreshSession(refresh_token, (err, session) => {
                    if (err) {
                        console.log(err);
                        reject(err);
                    } else {
                        resolve(true);
                    }
                });
            else
                reject(false);
        });

    }
}