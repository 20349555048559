import { Button, Link, TextField } from '@material-ui/core';
import React from 'react';
import './Login.scss';
import { Authorization } from '../../services/authorization';
import { useAppDispatch } from '../../app/hooks';
import { signIn } from '../../features/signIn/signInSlice';
import { useHistory } from 'react-router-dom';


const Login = () => {

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorText, setErrorText] = React.useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();

  if (Authorization.GetUsername()) {
    history.push("/userAccount");
  }

  const handleSignin = async () => {
    const signInResult = Authorization.SignIn(email, password);
    signInResult.then((res: any) => {
      dispatch(signIn());
      history.push("/userAccount");
    }).catch(err => {
      setError(true);
      setErrorText("Incorrect username or password.");
    })
  }

  return (
    <div className="Login" data-testid="Login">
      <div>
        <p><strong className="ft-25">Welcome to your Capacity Vault</strong></p>
        <p className="ft-22">
          If you have an account with us and you want to make a new recording, download your Capacity Vault Certificate or update your details please log in.<br /> If you have not got an account with us you can start making a Capacity Vault recording
          {' '} <span className="link" onClick={() => history.push('/')}>here</span>
        </p>
        <div className="field-container">
          <TextField id="standard-full-width" className="base-field"
            required
            fullWidth
            name="email"
            type="email"
            error={error}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{ shrink: true }} label="Email" placeholder="enter your email" variant="outlined" />
        </div>
        <div className="field-container">
          <TextField className="base-field"
            id="filled-full-width"
            label="Password"
            fullWidth
            type="password"
            //placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            placeholder="enter your password"
            autoComplete="current-password"
            helperText={errorText}
            error={error}
            InputLabelProps={{ shrink: true }} 
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
          />
        </div>
        <div className="link-container">
          <Link className="ft-22 link underline text-right" onClick={() => history.push('/forgotPassword')}>forgot your password?</Link>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            className="sumit-button"
            onClick={handleSignin}
          >
            Log In
          </Button>
        </div>
      </div>
    </div>
  )
};

export default Login;
