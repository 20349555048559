import axios from "axios";
import { SERVER_URL } from "../../config";
import { Authorization } from "../authorization";

interface Token {
  affiliateId: string;
  email: string;
}

export const checkRecordingToken = async (token: string) => {
  let res = null;
  try {
    res = await axios.get<Token>(SERVER_URL + "/recordingToken/check?token=" + token);
  } catch (e) { }
  return res;
}

export interface Voucher {
  active: boolean;
  isFixedPrice: boolean;
  value: number;
}

export const validateVoucher = async (voucherCode: string) => {
  let res = null;
  try {
    res = await axios.get<Voucher>(SERVER_URL + "/validateVoucher", {
      params: { voucherCode: voucherCode },
      headers: { Authorization: await Authorization.AuthToken() }
    });
  } catch (e) { }
  return res;
}

interface UpdateUserObj {
  county?: string;
  postcode?: string;
  address1?: string;
  address2?: string;
  dateOfBirth?: any;
  town?: string;
  name?: string;
  isSubscriber?: boolean;
  affiliateId?: string | null;
  mainStep?: number;
  affiliateToken?: string | null;
  signupComplete?: boolean | null;
}

export const updateUser = async (saveData: UpdateUserObj) => {
  return await axios.put(SERVER_URL + "/user", saveData, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
}

export const getCurrentUser = async () => {
  return await axios.get(SERVER_URL + "/user/current", {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
}

export interface Affiliate {
  affiliateId: string;
  affiliateName: string;
  commision: string;
  discount: string;
  maxDiscountAndCommision: string;
  url: string;
}

export const getAffiliateById = async (affiliateId: string) => {
  return await axios.get<Affiliate>(SERVER_URL + "/affiliate", { params: { affiliateId } });
}

export const getAffiliateByUrl = async (url: string) => {
  return await axios.get<Affiliate>(SERVER_URL + "/affiliate/url", { params: { url: url } });
}

export const combineVideo = async (videoId: string) => {
  return await axios.post(SERVER_URL + "/video/combine", { videoId }, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
}

// payment

export const syncSubscription = async () => {
  await axios.post(SERVER_URL + "/payment/syncSubscription", {}, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
}

interface SubscriptionData {
  error: string; // if status is not 200
  subscriptionId: string;
  subscription: any; // stripe subscription
}

export const paymentSubscription = async (voucherCode: string) => {
  return await axios.post<SubscriptionData>(SERVER_URL + "/payment/subscription", { voucherCode }, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
}

interface PaymentIntentData {
  paymentIntentSecret: string
}

export const getPaymentIntentSecret = async (voucherCode: string) => {
  const res = await axios.post<PaymentIntentData>(SERVER_URL + "/payment/paymentIntent", { voucherCode }, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });
  if (res) {
    return res.data.paymentIntentSecret;
  }
  return null;
}