import React, { FC, useContext, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { LinearProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Button, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';

import './Tutorial.scss';
import { isPhone } from '../../services/deviceService';
import ReactGA from "react-ga4";
import TenantContext from '../../contexts/tenantContext';

interface TutorialProps {
  next(): void;
}

enum StepState {
  Message,
  Video,
  KeyPoints,
  Timeout,
}

const Tutorial: FC<TutorialProps> = props => {
  const [activeStep, setActiveStep] = React.useState(() => {
    const storedStep = localStorage.getItem('activeTutorialStep');
    return storedStep ? parseInt(storedStep, 10) : 0;
  });

  const [activeStepState, setActiveStepState] = React.useState(() => {
    const storedStep = localStorage.getItem('activeTutorialStep');
    if (storedStep && parseInt(storedStep, 10) >= 1) {
      return StepState.KeyPoints;
    }
    return StepState.Message
  });
  const [resizeToggle, setResized] = React.useState(false);

  const [videoWatched, setVideoWatched] = React.useState(true);

  const [playing, setPlaying] = React.useState(true);
  const tenant = useContext(TenantContext);

  useEffect(() => {
    ReactGA.event({
      category: 'Progress',
      action: 'Tutorial Step Changed',
      label: activeStep.toString()
    });
    localStorage.setItem('activeTutorialStep', activeStep.toString());
  }, [activeStep]);


  const showPhone = useMediaQuery('(max-width:860px)');
  const isPhonePx = useMediaQuery('(max-width:560px)');

  const handleResize = () => {
    setResized(!resizeToggle);
  }

  const getStepContent = (step: number) => {
    if (isPhone() || showPhone) {
      return tenant.tutorialPhoneSteps[Math.abs(step)];
    }
    return tenant.tutorialSteps[Math.abs(step)];
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, []);

  var player = {} as any;

  const ref = (p: any) => {
    player = p
  }

  const onVideoEnded = () => {
    setActiveStepState(state => StepState.KeyPoints);
    setVideoWatched(true);
  }

  const handleNext = () => {

    if (activeStep === tenant.tutorialSteps.length - 1)
      props.next();

    if (activeStepState === StepState.KeyPoints || activeStepState === StepState.Video) {

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveStepState(() => StepState.Timeout);

      setTimeout(() => {
        setActiveStepState(() => getStepContent(activeStep + 1)?.message
          ? StepState.Message
          : StepState.Video);
      }, 150);
    }
    else {
      setActiveStepState(() => StepState.Video);
    }

    setVideoWatched(false);
  };

  const handleRepeat = () => {
    player.seekTo(0);
    setPlaying(true);
    setVideoWatched(false);
    setActiveStepState(() => StepState.Video);
  }
  const handleStart = () => {
    var video = player.getInternalPlayer();
    try {
      video.controlsList.value = "nodownload nofullscreen noremoteplayback";
    } catch { }
    video.disablePictureInPicture = true;
  }

  return (
    <>
      {tenant.tutorialSteps && <div className="tutorial">
        {(isPhone() || isPhonePx) ? <div>
          <LinearProgress className='phone-tutorial-progress' variant="determinate" value={(activeStep + 1) * 20} />
        </div> :
          <Stepper activeStep={activeStep} className="tutorial-stepper">
            {tenant.tutorialSteps.map((t, index) => {
              return (
                <Step key={index}>
                  <StepLabel></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        }
        <div>
          {activeStep === tenant.tutorialSteps.length ? (
            <div>
              All steps completed - you&apos;re finished
            </div>
          ) : (
            <div className="tutorial-content-container">
              {activeStepState === StepState.Message &&
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid className="message-container">
                    <Box height="20vh"></Box>
                    <Typography variant="h5" className="message"><span dangerouslySetInnerHTML={{ __html: getStepContent(activeStep).message || "" }} /></Typography>
                    <Box height="20vh"></Box>
                  </Grid>
                </Grid>
              }

              {(activeStepState === StepState.KeyPoints || activeStepState === StepState.Video) &&
                <Grid container direction="row" alignItems="center" justifyContent="center">
                  <Grid item className="tutorial-video">
                    {getStepContent(activeStep).videoUrl &&
                      <ReactPlayer ref={ref} playsinline playing={playing} volume={1} onStart={handleStart} pip={false}
                        style={activeStepState === StepState.KeyPoints ? { opacity: '0.05', transition: "opacity 500ms" } : { opacity: '100%' }}
                        controls={true} onEnded={onVideoEnded}
                        url={getStepContent(activeStep).videoUrl}
                        config={{
                          file: {
                            tracks: [
                              { kind: 'subtitles', src: `/subtitles/${tenant.tenantName}/tutorial/t${activeStep}.en.vtt`, label: '', srcLang: 'en', default: true }
                            ]
                          }
                        }}
                      />}
                  </Grid>
                </Grid>
              }

              {activeStepState === StepState.KeyPoints &&
                <div className="key-points-abs-container">
                  <div>
                    <div className="key-points-container">
                      <div>
                        <h2>Video tutorial {activeStep + 1}</h2>
                        <h3>Key points</h3>
                        <div className="text-container">
                          <p dangerouslySetInnerHTML={{ __html: getStepContent(activeStep).keyPoints }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          )}
        </div>
        {activeStepState === StepState.Video &&
          <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
            className="green-submit-button skip-tutorial-button"
            variant="contained"
            color="primary"
            onClick={() => {
              try {
                player.player.handleEnded();
                const video = player.player.player.player;
                video.currentTime = video.duration - 0.2;
                video.pause();
              } catch (e) {
                console.log('skip error', e)
              }
            }}
          >
            Skip
          </Button>}
        {videoWatched &&
          <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
            className="green-submit-button"
            variant="contained"
            color="primary"
            onClick={handleNext}
          >
            I understand
          </Button>
        }
        {videoWatched && activeStepState > 0 &&
          <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
            className="tutorial-repeat-button"
            variant="contained"
            color="primary"
            onClick={handleRepeat}
          >
            Repeat
          </Button>
        }
      </div>}
    </>
  );
}

export default Tutorial;
