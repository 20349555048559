import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { isIOS, isIOS13, isMobileSafari } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { Grid } from '@material-ui/core';

import './ScreenChecks.scss';
import { Authorization } from '../../services/authorization';
import { isPhone } from '../../services/deviceService';
import WebcamStreamCapture from '../WebcamStreamCapture/WebcamStreamCapture';
import TenantContext from '../../contexts/tenantContext';


const subtitles = [
  'What is your full name?',
  'Please state your date of birth',
  `What's your favourite song?`
];

const ScreenChecks = (props: any, ref: any) => {
  const [prevStep, setPrevStep] = useState(0);
  const [username] = useState(Authorization.GetUsername() ?? '');
  const [video, setVideo] = useState<Blob | null>(null);
  const [isRecordingStarted, setRecordingStarted] = useState(false);
  const [isPlaying, setPlay] = useState(false);
  const [step, setStep] = useState(0);
  const [cameraReady, setCameraReady] = useState(false);

  const tenant = useContext(TenantContext);

  const [internalPlayer, setPlayer] = useState(null as any);
  var [source, setSource] = useState(null as any);
  const [lastUrl, setLastUrl] = useState("");

  const [audioContext, setAudioContext] = useState(null as any);

  const playerRef = (player: any) => {
    if (player && player.player.props.url !== lastUrl) {
      setLastUrl(player.player.props.url);
      setPlayer(player);
    }
  }

  // Safari sound fix.
  const mixAudio = () => {
    if (isMobileSafari || isIOS || isIOS13) {
      if (internalPlayer != null) {
        if (!source) {

          var context = new AudioContext()
          setAudioContext(context);
          var player = internalPlayer!.getInternalPlayer();

          var alexSource = context.createMediaElementSource(player);
          setSource(alexSource);
          var gain = context.createGain();

          gain.gain.value = 8;

          alexSource.connect(gain);
          gain.connect(context.destination);

        }
      }
    }
  }

  /* landscape detection */
  const isHorizontal = () => {
    // Apple does not seem to have the window.screen api so we have to use deprecated window.orientation instead.
    if (window.orientation && typeof window.orientation === "number" && Math.abs(window.orientation) === 90) {
      return true;
    }
    if (window.screen.orientation && window.screen.orientation.type.includes('/^landscape-.+$/') === true) {
      return true;
    }
    return false;
  };

  const [horizontal, setHorizontal] = React.useState(isHorizontal());

  React.useEffect(() => {
    window.addEventListener("orientationchange", (event: any) => {
      setHorizontal(isHorizontal());
    });
  }, []);
  /* landscape detection */


  useImperativeHandle(ref, () => ({
    async next() {
      if (prevStep === 0) {
        setPrevStep(1);
        return false;
      }
      if (prevStep === 1) {
        props.setNextButtonDisabledState(true);
        setPlay(true);
        setPrevStep(2);
        return false;
      }
      if (step < tenant.screenChecks.length - 1) {
        props.setNextButtonDisabledState(true);
        setPlay(true);
        setStep(step + 1);
        return false;
      } else if (step === tenant.screenChecks.length - 1) {
        setRecordingStarted(false);
        if (audioContext) {
          audioContext.close();
          return false;
        }
      } else {
        return true;
      }
    }
  }));

  const renderRecordDescription = () => {
    if (isRecordingStarted && prevStep === 2) {
      return (
        <div>
          <div className={`test-audio-description ${!isPlaying ? "invisible-alex" : "alex-video-shadow"}`}>
            <ReactPlayer
              ref={playerRef}
              config={{
                file: {
                  attributes: {
                    crossOrigin: "anonymous"
                  }
                }
              }}
              playing={isPlaying}
              volume={1.0}
              playsinline
              url={tenant.screenChecks[step].videoUrl}
              onProgress={() => {
                (cameraReady === false) && setCameraReady(true)
              }}
              onReady={() => mixAudio()}
              onEnded={() => { props.setNextButtonDisabledState(false); setPlay(false) }}
            />
          </div>
          {cameraReady &&
            <div className="check-screen-subtitle">
              <span>{subtitles[step]}</span>
            </div>}
        </div>
      );
    }
    return <div />;
  }

  const renderVideo = () => {
    // if (video){
    const url = URL.createObjectURL(video ?? new Blob());
    return (
      <div className="test-audio-record">
        <ReactPlayer playing={true} volume={1} playsinline url={url} controls={true} />
      </div>
    );
    // }
  }

  let className = "ScreenChecks";

  if (isPhone() && horizontal) {
    className += ' Horizontal';
  }

  if (prevStep === 0) {
    return (
      <Grid container direction="row" alignItems="center" justifyContent="center">
        <Grid className="Recording">
          <div className="message-content ft-35">
            <div>
              You are now ready for a quick screen test. This will check that your camera and microphone are working.
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={className} data-testid="ScreenChecks">
      {step === 3 ? renderVideo()
        :
        <div>
          <div id="circle"><div></div></div>
          {renderRecordDescription()}
          <WebcamStreamCapture
            onMouted={() => setRecordingStarted(true)}
            username={username}
            screenCheck={true}
            saveVideoRecording={false}
            isRecordingStarted={isRecordingStarted}
            onRecordingStop={(videoBlob) => {
              setVideo(videoBlob);
              setStep(3);
            }} />
        </div>}
    </div>
  );
}

export default forwardRef(ScreenChecks);
