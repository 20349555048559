import React, { forwardRef, useEffect } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import validator from 'validator'

import './UserDetails.scss';
import { updateUser } from '../../services/axios/requests';

interface UserDetailsProps {
  user: any;
};

const UpdateUserDetails = (props: UserDetailsProps, ref: any) => {
  const [success, setSuccess] = React.useState(false);
  const [user, setUser] = React.useState({ ...props.user } as any);
  var [validationErrors, setValidationErrors] = React.useState({} as any);

  const handleDateChange = (date: any) => {
    user.dateOfBirth = date;
    setUser({ ...user });
    validate();
  };

  const handleUserChange = (event: any) => {
    var value = event.target.name == "postcode"
      ? event.target.value.toUpperCase()
      : event.target.value;

    user[(event.target.name as string)] = value;
    
    setUser({ ...user });
    validate();
  }

  const validate = () => {

    validationErrors = {};

    if (!user.name)
      validationErrors.name = "Name is required";

    if (!user.dateOfBirth)
      validationErrors.dateOfBirth = "Date of birth is required";

    if (!user.email)
      validationErrors.email = "Email address is required";

    if (user.email && !validator.isEmail(user.email))
      validationErrors.email = "Email address is not valid";

    if (!user.confirmEmail)
      validationErrors.confirmEmail = "Email address is required";

    if (user.confirmEmail && !validator.isEmail(user.confirmEmail))
      validationErrors.confirmEmail = "Email address is not valid";

    if (user.email !== user.confirmEmail)
      validationErrors.confirmEmail = "Email addresses do not match";

    if (!user.address1)
      validationErrors.address1 = "Address is required";

    if (!user.town)
      validationErrors.town = "Town is required";

    if (!user.postcode)
      validationErrors.postcode = "Postcode is required";

    setValidationErrors({ ...validationErrors });
  }

  const handleSubmit = async () => {

    let saveData = {
      address1: user.address1,
      address2: user.address2,
      county: user.county,
      dateOfBirth: user.dateOfBirth,
      name: user.name,
      postcode: user.postcode,
      town: user.town
    }

    const res = await updateUser(saveData);

    if (res && res.status === 200) {
      setSuccess(true);
    }
  };

  useEffect(() => {
    var dd = document.getElementById('date-picker-inline-label');
    if (dd) {
      dd.remove();
    }
  }, [])

  return (
    <form>
      <div className="UserDetails UpdateUserDetails" data-testid="UserDetails">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="details-box">
            <Grid container item sm={12} md={6}>
              <TextField id="standard-full-width"
                required
                helperText={validationErrors.name}
                error={validationErrors.name}
                name="name"
                value={user.name}
                onChange={handleUserChange}
                InputLabelProps={{ shrink: true }} label="Name" placeholder="Enter your full name" variant="outlined" />
              <div className="MuiFormControl-root">
                <div className="input-label">Date of Birth</div>
                <KeyboardDatePicker
                  name="dateOfBirth"
                  inputVariant="outlined"
                  disableToolbar
                  variant="inline"
                  className="date-of-birth"
                  format="dd/MM/yyyy"
                  margin="normal"
                  placeholder="DD/MM/YYYY"
                  id="date-picker-inline"
                  label="Date of birth"
                  value={user.dateOfBirth}
                  helperText={validationErrors.dateOfBirth}
                  error={validationErrors.dateOfBirth}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </div>
              <TextField id="standard-full-width"
                required
                name="email"
                disabled={true}
                helperText={validationErrors.email}
                value={user.id}
                error={validationErrors.email}
                onChange={handleUserChange}
                InputLabelProps={{ shrink: true }} label="Email" placeholder="Enter your email" variant="outlined" />
            </Grid>
            <Grid container item sm={12} md={6}>
              <TextField id="outlined-search"
                required
                name="address1"
                value={user.address1}
                helperText={validationErrors.address1}
                error={validationErrors.address1}
                onChange={handleUserChange}
                InputLabelProps={{ shrink: true }}
                label="Building and street"
                className="line-address"
                placeholder="Enter line address 1"
                variant="outlined" />
              <TextField
                name="address2"
                value={user.address2}
                onChange={handleUserChange}
                className="line-address second-line-address"
                id="outlined-search"
                placeholder="Enter line address 2"
                InputLabelProps={{ shrink: true }}
                variant="outlined" />
              <TextField id="outlined-search" required
                name="town"
                value={user.town}
                helperText={validationErrors.town}
                error={validationErrors.town}
                onChange={handleUserChange}
                InputLabelProps={{ shrink: true }} label="Town or city" placeholder="Enter your town or city" variant="outlined" />
              <TextField id="outlined-search"
                name="county"
                value={user.county}
                onChange={handleUserChange}
                InputLabelProps={{ shrink: true }} label="County" placeholder="Enter your country" variant="outlined" />
              <TextField id="outlined-search"
                name="postcode"
                value={user.postcode}
                helperText={validationErrors.postcode}
                error={validationErrors.postcode}
                onChange={handleUserChange}
                required InputLabelProps={{ shrink: true }} label="Postcode" placeholder="Enter your zip code" variant="outlined" />
            </Grid>
          </div>
        </MuiPickersUtilsProvider>
      </div>
      <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
        className="save-button"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Save
      </Button>
      <Dialog open={success} onClose={() => setSuccess(false)} className="LogoutPopup update-success-dialog">
        <DialogTitle>
          Thank you, your details have been updated.
        </DialogTitle>
        <div className="btn-row" style={{ display: " flex", alignItems: 'center', justifyContent: 'center' }}>
          <Button
            className="save-button"
            variant="contained"
            color="primary"
            onClick={() => setSuccess(false)}
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </form >
  );
};

export default forwardRef(UpdateUserDetails);
