import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import './CancelSubscription.scss';
import axios from 'axios';
import { Authorization } from '../../services/authorization';

const CancelSubscription = () => {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);

  const [cancelClicked, setClicked] = useState(false);

  const cancelSubscription = async () => {

    if (cancelClicked) {
      return;
    }
    setClicked(true);

    const res = await axios.post(SERVER_URL + "/payment/cancelSubscription", { }, {
      headers: {
        Authorization: await Authorization.AuthToken()
      }
    });

    if (res.status === 200) {
      history.push('/userAccount');
    }
    setClicked(false);
  }

  return (
    <div className="CancelSubscription">
      <div className="ft-35 message-content">
        <strong>Your Capacity Vault subscription costs £10 per year. The first year is free.</strong> Your subscription includes free updated recordings whenever you update your will. It also allows those closest to you to access your recordings for free if they need them after you die.
      </div>
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Cancel
      </Button>
      <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
        className="save-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Retain
      </Button>
      <Dialog open={isOpen} className="cancel-subsciption-dialog">
        <div className="dialog-box">
        <div className="dialog-text ft-22">
          <div>
            Are you sure you want to cancel your subscription? This cannot be undone. Click "yes" to cancel and "no" to return to your account.
          </div>
        </div>
        <div className="btn-row">
          <Button
            className="back-button"
            variant="contained"
            color="primary"
            onClick={cancelSubscription}
          >
            Yes
          </Button>
          <Button
            className="save-button"
            variant="contained"
            color="primary"
            onClick={() => history.push('/userAccount')}
          >
            No
          </Button>
        </div>
        </div>
      </Dialog>
    </div>
  );
}

export default CancelSubscription;
