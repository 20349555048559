import { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import './AffiliateLanding.scss';

import { useAppDispatch } from '../../app/hooks';
import { saveAffiliate } from '../../features/affiliate/affiliateSlice';
import { getAffiliateById, getAffiliateByUrl } from '../../services/axios/requests';

const AffiliateLanding = () => {

  const dispatch = useAppDispatch();

  const history = useHistory();

  let { url, affiliateId } = (useParams() as any);

  const dispatchAffiliate = async () => {

    console.log(`Affiliate url: ${url}`)

    const affiliate = url !== undefined
      ? await getAffiliateByUrl(url)
      : await getAffiliateById(affiliateId);

    dispatch(saveAffiliate(affiliate.data));

    history.push(`/`);
  }

  useEffect(() => {
    dispatchAffiliate();
    // eslint-disable-next-line
  }, [])


  return (
    <></>
  );
}

export default AffiliateLanding;

