import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { forwardRef, useImperativeHandle } from "react";

import "./UserPassword.scss";
import _ from "lodash";
import { Authorization } from "../../services/authorization";
import { useAppDispatch } from "../../app/hooks";

import { signIn } from "../../features/signIn/signInSlice";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PasswordInput from "../PasswordInput/PasswordInput";

interface UserPasswordProps {
  setNextButtonDisabledState: any;
  user: any;
  updatePassword?: boolean;
  confirmPasswordUsername?: string;
}

const UserPassword = (props: UserPasswordProps, ref: any) => {
  var [showPassword, setShowPassword] = React.useState(false);
  var [oldPassword, setOldPassword] = React.useState("");
  var [password, setPassword] = React.useState("");
  const [confirmErrorMessage, setConfirmErrorMessage] = React.useState("");
  const [confirmError, setConfirmError] = React.useState(false);
  var [confirmPassword, setConfirmPassword] = React.useState("");
  var [confirmationCode, setConfirmationCode] = React.useState("");
  var [validationErrors, setValidationErrors] = React.useState({} as any);
  const [nextClicked, setNextClicked] = React.useState(false);

  var updatePassword = props.updatePassword;
  var confirmPasswordUsername = props.confirmPasswordUsername;

  const dispatch = useAppDispatch();

  var user = props.user;

  useImperativeHandle(ref, () => ({
    async next() {
      setNextClicked(true);

      if (!validate(true)) {
        return;
      }
      if (props.updatePassword === true) {
        await Authorization.ChangePassword(oldPassword, password);
        return;
      }
      if (confirmPasswordUsername) {
        const promise = Authorization.ConfirmPassword(
          confirmPasswordUsername,
          confirmationCode,
          password
        );
        const res = await promise
          .then(() => {
            return true;
          })
          .catch(() => {
            setConfirmErrorMessage("Incorrect verifcation code entered");
            setConfirmError(true);
            return false;
          });
        return res;
      } else {
        user.cognito = await Authorization.Register(
          user.email,
          password,
          user.email
        );
        var isSignedIn = await Authorization.SignIn(user.email, password);
        if (isSignedIn) dispatch(signIn());
        return user;
      }
    },
  }));

  const validate = (nextClickedInside?: boolean) => {
    if (!nextClicked && !nextClickedInside) {
      return;
    }

    validationErrors = {};

    const upperFormat = /[A-Z].*$/;
    const hasUpperCharater = upperFormat.test(password);

    if (!hasUpperCharater) {
      validationErrors.confirmPassword =
        "Password must contain at least 1 capital letter";
    }

    const lowerFormat = /[a-z].*$/;
    const hasLowerCharater = lowerFormat.test(password);

    if (!hasLowerCharater) {
      validationErrors.confirmPassword =
        "Password must contain at least 1 lowercase letter";
    }

    const numberFormat = /\d.*$/;
    const hasNumberCharater = numberFormat.test(password);

    if (!hasNumberCharater) {
      validationErrors.confirmPassword =
        "Password must contain at least 1 number";
    }

    if (password.length < 8) {
      validationErrors.confirmPassword =
        "Password must be at least 8 characters long";
    }

    if (!password) validationErrors.password = "Password is required";

    if (!confirmPassword)
      validationErrors.confirmPassword = "Passwords do not match";

    if (password !== confirmPassword)
      validationErrors.confirmPassword = "Passwords do not match";

    setValidationErrors({ ...validationErrors });

    props.setNextButtonDisabledState(!_.isEmpty(validationErrors));
    return _.isEmpty(validationErrors);
  };

  const handleSubmit = (event: any) => {
    if (!validate()) {
      event.preventDefault();
    }
  };

  return (
    <div className="UserPassword" data-testid="UserPassword">
      <form onSubmit={handleSubmit}>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid className="password-message">
            <Typography variant="h5" className="message">
              <span>
                <span className="bold">Please choose a secure password.</span>{" "}
                Your password must contain at least 8 characters including at
                least one capital letter and one number.
              </span>
            </Typography>
          </Grid>
          {updatePassword === true && (
            <Grid container item xs={12}>
              <PasswordInput
                id={"oldPassword"}
                label={"Old Password"}
                name={"Old Password"}
                placeholder={"Enter your old password"}
                autoComplete={"old-password"}
                password={oldPassword}
                onChange={(e: any) => {
                  oldPassword = e.target.value;
                  setOldPassword(oldPassword);
                  validate();
                }}
                error={""}
              />
            </Grid>
          )}
          {confirmPasswordUsername && (
            <Grid container item xs={12}>
              <TextField
                className="base-field"
                value={confirmationCode}
                onChange={(e) => {
                  confirmationCode = e.target.value;
                  setConfirmationCode(confirmationCode);
                  validate();
                }}
                fullWidth
                id="verification-code"
                label="Verification code"
                name="Verification code"
                placeholder="Enter verification code"
                variant="outlined"
                helperText={confirmErrorMessage}
                error={confirmError}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          )}
          <Grid container item xs={12}>
            <PasswordInput
              id={"Password"}
              label={"Password"}
              name={"Password"}
              placeholder={"Enter your password"}
              autoComplete={"current-password"}
              password={password}
              onChange={(e: any) => {
                password = e.target.value;
                setPassword(password);
                validate();
              }}
              error={validationErrors.password}
            ></PasswordInput>
          </Grid>
          <Grid container item xs={12}>
            <PasswordInput
              id={"confirmPassword"}
              label={"Repeat the Password"}
              name={"ConfirmPassword"}
              placeholder={"Confirm password"}
              autoComplete={"repeat-password"}
              password={confirmPassword}
              onChange={(e: any) => {
                confirmPassword = e.target.value;
                setConfirmPassword(confirmPassword);
                validate();
              }}
              error={validationErrors.confirmPassword}
            ></PasswordInput>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default forwardRef(UserPassword);
