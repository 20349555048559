import { Checkbox, Grid, TextField, FormControl } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios';
import _ from 'lodash';

import './UserDetails.scss';
import { LANDING_URL, SERVER_URL } from '../../config';
import { Authorization } from '../../services/authorization';
import { isPhone } from '../../services/deviceService';
import UserDetailsService from './UserDetailsService';
import { signIn } from '../../features/signIn/signInSlice';
import ToogleButton from '../ToggleButton/ToggleButton';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAffiliate } from '../../features/affiliate/affiliateSlice'
import { IGeolocation } from '../AccountCreation/AccountCreation';
import PasswordInput from '../PasswordInput/PasswordInput';
import { GetRecordingToken } from '../../services/localStorage/localstorage';

interface UserDetailsProps {
  geolocation: IGeolocation;
  setSubmitting(value: boolean): void;
};

export interface User {
  name?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
  termsAccepted?: boolean;
  cognito?: any;
  isAnonymous?: number;
  affiliateId?: string;
  affiliateToken?: string;
  geolocation?: string;  // JSON string of Geolocation 
}


const UserDetails = (props: UserDetailsProps, ref: any) => {
  const [user, setUser] = React.useState({} as User);
  const [showErrors, setShowErrors] = React.useState(false);
  const [nextClicked, setNextClicked] = React.useState(false);

  const dispatch = useAppDispatch();
  const affiliate = useAppSelector(selectAffiliate);

  var [validationErrors, setValidationErrors] = React.useState({} as any);

  useEffect(() => {
    if (sessionStorage.getItem("iframe-name")) {
      setUser({
        name: sessionStorage.getItem("iframe-name") || "",
        email: sessionStorage.getItem("iframe-email") || "",
      })

      sessionStorage.removeItem("iframe-email");
      sessionStorage.removeItem("iframe-name");
    }
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    async next() {
      setNextClicked(true);

      setShowErrors(true);
      validateNext();
      const isValid = checkValid();

      if (!isValid) {
        return;
      }

      window.scroll(0, -4000);
      props.setSubmitting(true);

      if (!await validateEmailAddressUniques()) {
        props.setSubmitting(false);
        return false;
      }

      user.geolocation = JSON.stringify(props.geolocation);
      if (affiliate?.affiliateId)
        user.affiliateId = affiliate.affiliateId

      var affiliateRecordingToken = GetRecordingToken();
      if (affiliateRecordingToken)
        user.affiliateToken = affiliateRecordingToken;

      if (user.email && user.password) {
        const url = SERVER_URL + "/user";
        await axios.post(url, user, {});
        props.setSubmitting(false);

        user.cognito = await Authorization.Register(user.email, user.password, user.email);
        var isSignedIn = await Authorization.SignIn(user.email, user.password);
        if (isSignedIn)
          dispatch(signIn());
        return user;
      }
      return false;
    }
  }));

  const handleUserChange = (event: any) => {
    const column = event.target.name as keyof User;

    user[column] = event.target.value;

    setUser({ ...user });

    validate();
  }

  const validateEmailAddressUniques = async () => {

    var emailExists = await axios.get(SERVER_URL + "/useremail", { params: { email: user.email } });

    if (emailExists && emailExists.data) {
      validationErrors.email = "Email address is already used";
      setValidationErrors({ ...validationErrors });
      return false;
    }

    return true;
  }

  const checkValid = () => {
    const errors = UserDetailsService.validate(user);
    return _.isEmpty(errors);
  }

  const validate = () => {
    if (!nextClicked) {
      return [];
    }
    validationErrors = UserDetailsService.validate(user);
    setValidationErrors({ ...validationErrors });
  }

  const validateNext = () => {
    validationErrors = UserDetailsService.validate(user);
    setValidationErrors({ ...validationErrors });
  }

  return (
    <form className="UserDetailsForm">
      <div className="UserDetails" data-testid="UserDetails">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {isPhone() ? (
            <div className="details-box">
              <Grid container item xs={12}>
                <TextField
                  id="name"
                  autoComplete="name"
                  required
                  helperText={showErrors ? validationErrors.name : ""}
                  error={showErrors ? validationErrors.name : false}
                  name="name"
                  value={user.name}
                  onChange={handleUserChange}
                  InputLabelProps={{ shrink: true }}
                  label="Name"
                  placeholder="Enter your full name"
                  variant="outlined"
                />
                <TextField
                  required
                  id="email"
                  type="email"
                  name="email"
                  helperText={showErrors ? validationErrors.email : ""}
                  error={showErrors ? validationErrors.email : false}
                  value={user.email}
                  onChange={handleUserChange}
                  InputLabelProps={{ shrink: true }}
                  label="Email"
                  placeholder="Enter your email"
                  variant="outlined"
                />
                <Grid container item xs={12}>
                  <PasswordInput
                    id={"Password"}
                    label={"Password"}
                    name={"password"}
                    placeholder={"Enter your password"}
                    autoComplete={"current-password"}
                    password={user.password}
                    onChange={handleUserChange}
                    error={validationErrors.password}
                  ></PasswordInput>
                </Grid>
                <Grid container item xs={12}>
                  <PasswordInput
                    id={"confirmPassword"}
                    label={"Repeat the Password"}
                    name={"confirmPassword"}
                    placeholder={"Confirm password"}
                    autoComplete={"repeat-password"}
                    password={user.confirmPassword}
                    onChange={handleUserChange}
                    error={validationErrors.confirmPassword}
                  ></PasswordInput>
                </Grid>
                {affiliate?.affiliateName && (
                  <FormControl>
                    <label className="label-r5">
                      <b>{affiliate.affiliateName}</b> would love to know your
                      name and email - are you happy to share?
                    </label>
                    <ToogleButton
                      firstValue={0}
                      secondValue={1}
                      currentValue={user.isAnonymous}
                      update={(isAnonymous: number) =>
                        setUser({ ...user, isAnonymous })
                      }
                    />
                  </FormControl>
                )}
                <Grid
                  container
                  item
                  xs={12}
                  className={`flex-inline accept-condition-check ft-18 ${
                    nextClicked && !user.termsAccepted ? "invalid" : ""
                  }`}
                  onClick={() =>
                    setUser({ ...user, termsAccepted: !user.termsAccepted })
                  }
                >
                  <Checkbox
                    id="termsAndConditionsCheckbox"
                    checked={user.termsAccepted}
                  />
                  <div className="font-light">
                    I agree to the{" "}
                    <a
                      className="blue"
                      rel="noreferrer"
                      href={LANDING_URL + "/terms-and-conditions"}
                      target="_blank"
                    >
                      terms and conditions
                    </a>
                  </div>
                  {nextClicked && !user.termsAccepted && (
                    <div className="MuiFormHelperText-root Mui-error">
                      You must accept the terms and conditions
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="details-box">
              <div>
                <div>
                  <TextField
                    required
                    id="name"
                    helperText={showErrors ? validationErrors.name : ""}
                    error={showErrors ? validationErrors.name : false}
                    name="name"
                    autoComplete="name"
                    value={user.name}
                    onChange={handleUserChange}
                    InputLabelProps={{ shrink: true }}
                    label="Full Name (including middle names)"
                    placeholder="Enter your full name"
                    variant="outlined"
                  />
                </div>
                <div>
                  <TextField
                    required
                    id="email"
                    type="email"
                    name="email"
                    helperText={showErrors ? validationErrors.email : ""}
                    error={showErrors ? validationErrors.email : false}
                    value={user.email}
                    onChange={handleUserChange}
                    InputLabelProps={{ shrink: true }}
                    label="Email"
                    placeholder="Enter your email"
                    variant="outlined"
                  />
                </div>
                <div>
                  <PasswordInput
                    id={"Password"}
                    label={"Password"}
                    name={"password"}
                    placeholder={"Enter your password"}
                    autoComplete={"current-password"}
                    password={user.password}
                    onChange={handleUserChange}
                    error={validationErrors.password}
                  ></PasswordInput>
                </div>
                <div>
                  <PasswordInput
                    id={"confirmPassword"}
                    label={"Repeat the Password"}
                    name={"confirmPassword"}
                    placeholder={"Confirm password"}
                    autoComplete={"repeat-password"}
                    password={user.confirmPassword}
                    onChange={handleUserChange}
                    error={validationErrors.confirmPassword}
                  ></PasswordInput>
                </div>
                {affiliate?.affiliateName && (
                  <FormControl>
                    <label className="label-r5">
                      <b>{affiliate.affiliateName}</b> would love to know your
                      name and email - are you happy to share?
                    </label>
                    <ToogleButton
                      firstValue={0}
                      secondValue={1}
                      currentValue={user.isAnonymous}
                      update={(isAnonymous: number) =>
                        setUser({ ...user, isAnonymous })
                      }
                    />
                  </FormControl>
                )}
                <div
                  className={`flex-inline accept-condition-check ft-18 ${
                    nextClicked && !user.termsAccepted ? "invalid" : ""
                  }`}
                  onClick={() =>
                    setUser({ ...user, termsAccepted: !user.termsAccepted })
                  }
                >
                  <Checkbox
                    id="termsAndConditionsCheckbox"
                    checked={user.termsAccepted}
                  />
                  <div className="font-light">
                    I agree to the{" "}
                    <a
                      className="blue"
                      rel="noreferrer"
                      href={LANDING_URL + "/terms-and-conditions"}
                      target="_blank"
                    >
                      terms and conditions
                    </a>
                  </div>
                </div>
                {nextClicked && !user.termsAccepted && (
                  <div className="MuiFormHelperText-root Mui-error MuiFormHelperText-contained">
                    You must accept the terms and conditions
                  </div>
                )}
              </div>
            </div>
          )}
        </MuiPickersUtilsProvider>
      </div>
    </form>
  );
};

export default forwardRef(UserDetails);
