import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import signInReducer from '../features/signIn/signInSlice';
import mainStepReducer from '../features/mainStep/mainStepSlice';
import affiliateReducer from '../features/affiliate/affiliateSlice';

export const store = configureStore({
  reducer: {
    mainStep: mainStepReducer,
    signIn: signInReducer,
    affiliate: affiliateReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
