export const RecordingToken = "RecordingToken";


export function SetRecordingToken(token: string) {
  localStorage.setItem(RecordingToken, token);
}

export function GetRecordingToken() {
  return localStorage.getItem(RecordingToken);
}

export function UnsetRecordingToken() {
  localStorage.removeItem(RecordingToken);
}
