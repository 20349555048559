import React, { useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import ReactGA from "react-ga4";
import { useLocation } from 'react-router-dom';

import AccountCreation from '../AccountCreation/AccountCreation';
import Recording from '../Recording/Recording';
import Tutorial from '../Tutorial/Tutorial';
import './MainSteps.scss';
import { Authorization } from '../../services/authorization';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setStep } from '../../features/mainStep/mainStepSlice';
import { selectAffiliate } from '../../features/affiliate/affiliateSlice'
import { isPhone } from '../../services/deviceService';
import Payment from '../Payment/Payment';
import SetUserDetails from '../UserDetails/SetUserDetails';
import FinalSteps from '../FinalSteps/FinalSteps';

export enum MainStepEnum {
  TutorialStep = 0,
  AccountCreationStep,
  RecordingStep,
  DetailsStep,
  PaymentStep,
  FinalStepsStep
}

function getSteps() {
  return ['Tutorial', 'SignUp', 'Snapshot', 'Details', 'Completion', ''];
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function MainSteps() {
  // user recording additional video
  const [isNewRecording, setIsNewRecording] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(() => {
    const storedStep = localStorage.getItem('activeMainStep');
    return storedStep ? parseInt(storedStep, 10) : 0;
  });
  const dispatch = useAppDispatch();
  const affiliate = useAppSelector(selectAffiliate);
  console.log(`Affiliate selected: ${JSON.stringify(affiliate)}`);
  const steps = getSteps();
  let query = useQuery();

  useEffect(() => {
    const beforeunloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
  
    window.addEventListener("beforeunload", beforeunloadCallback);
      return () => window.removeEventListener("beforeunload", beforeunloadCallback);
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: 'Progress',
      action: 'Main Step Changed',
      label: MainStepEnum[activeStep]
    });
  }, [activeStep]);

  const setUsername = async () => {
    var tokenIsRefreshed = await Authorization.RefreshToken();
    if (!tokenIsRefreshed) {
      console.log("User token can not be refreshed.")
      if (activeStep >= MainStepEnum.RecordingStep) {
        localStorage.removeItem('activeMainStep');
        localStorage.removeItem('activeAccountStep');
        localStorage.removeItem('activeTutorialStep');
        alert("Please log in");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
    else {
      const username = await Authorization.GetUsername();

      console.log("redirect already logged in user to Recording page.")
      if (username) {
        // 
      }
      if (query.get("newRecording")) {
        setIsNewRecording(true);
        if (activeStep <= 1) {
          dispatch(setStep(MainStepEnum.RecordingStep));
          setActiveStep(2);
        }
      }
    }
  }

  useEffect(() => {
    setUsername();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeStep < 3)
      localStorage.setItem('activeMainStep', activeStep.toString());
  }, [activeStep]);


  function getStepContent(step: number) {
    switch (step) {
      case MainStepEnum.TutorialStep:
        return { com: <Tutorial next={handleNext} /> };
      case MainStepEnum.AccountCreationStep:
        return { com: <AccountCreation next={handleNext} /> };
      case MainStepEnum.RecordingStep:
        return { com: <Recording next={handleNext} /> };
      case MainStepEnum.DetailsStep:
        return { com: <SetUserDetails next={handleNext} /> };
      case MainStepEnum.PaymentStep:
        return { com: <Payment next={handleNext} /> };
      default:
        return { com: <FinalSteps /> };
    }
  }

  const handleNext = () => {
    if (isNewRecording) {
      setActiveStep(MainStepEnum.FinalStepsStep);
    } else {
      setActiveStep((prevActiveStep) => {
        dispatch(setStep(prevActiveStep + 1));
        localStorage.setItem('activeAccountStep', "0");
        localStorage.setItem('activeTutorialStep', "0");
        return prevActiveStep + 1;
      });
    }
  };

  const renderFirstStep = () => {
    return (
      <span>
        <span className="desktop">{steps[0]}</span>
        <span className="phone">Tutorials</span>
      </span>
    );
  }

  const renderStepper = () => {
    const renderIcon = (index: number) => {
      if (index !== activeStep) {
        return <CheckIcon className={index < activeStep ? 'completed' : ''} />
      }
      return '';
    }

    if (isPhone()) {
      return (
        <Stepper activeStep={activeStep} className={`main-stepper phone-stepper stepper-step-${activeStep}`}>
          {steps.map((label, index) => {
            if (index === activeStep) {
              return (
                label
                  ? <Step key={label} className={`${index === activeStep ? 'active active-' + index : 'not-active not-active-' + index} ${index === 0 ? 'first-step' : ''}`}>
                    <StepLabel><span>{index === 0 ? renderFirstStep() : label}</span> {renderIcon(index)}</StepLabel>
                  </Step>
                  : <div key={`step-${index}`}></div>
              );
            }
            return (
              label
                ? <Step key={label} className={`${index === activeStep ? 'active active-' + index : 'not-active not-active-' + index} ${index === 0 ? 'first-step' : ''}`}>
                  <StepLabel><span>{index + 1}</span> {renderIcon(index)}</StepLabel>
                </Step>
                : <div key={`step-${index}`}></div>
            );
          })}
        </Stepper>
      );
    }

    return (
      <Stepper activeStep={activeStep} className={`main-stepper stepper-step-${activeStep}`}>
        {steps.map((label, index) => {
          return (
            label
              ? <Step key={label} className={`${index === activeStep ? 'active active-' + index : 'not-active not-active-' + index} ${index === 0 ? 'first-step' : ''}`}>
                <StepLabel><span>{index === 0 ? renderFirstStep() : label}</span> {renderIcon(index)}</StepLabel>
              </Step>
              : <div key={`step-${index}`}></div>
          );
        })}
      </Stepper>
    )
  }

  return (
    <div className="MainSteps">
      {renderStepper()}
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography>
              All steps completed - you&apos;re finished
            </Typography>
          </div>
        ) : getStepContent(activeStep).com}
      </div>
    </div>
  );
}