import React from 'react'

export interface TutorialStep {
    keyPoints: string;
    message?: string;
    videoUrl: string;
}

export interface ScreenCheck {
    videoUrl: string;
}

export interface RecordingStep {
    videos: string[];
}

export interface Tenant {
    tenantId: string;
    tenantName: string;
    tutorialSteps: TutorialStep[];
    tutorialPhoneSteps: TutorialStep[];
    screenChecks: ScreenCheck[];
    recordingSteps: RecordingStep[];
    finalSteps: string[];
}

const TenantContext = React.createContext({
    tenantId: "",
    tenantName: "",
    tutorialSteps: []as any[],
    tutorialPhoneSteps: []as any[],
    screenChecks: [] as any[],
    recordingSteps: []as any[],
    finalSteps: []as any[],
} as Tenant);

export const TenantProvider = TenantContext.Provider

export default TenantContext