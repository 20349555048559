import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  domain: string;
  signedIn: boolean;
  handleSignIn(): void;
}

const Header: React.FC<Props> = ({domain, ...props}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="right-menu phone">
      <Button aria-controls="main-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </Button>
      <Menu
        id="main-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <CloseIcon className="close-icon" onClick={handleClose} />
        <div className="menu-list">
        <MenuItem onClick={() => {
          window.location.href = `${domain}/how-it-works`;
          handleClose();
        }}>HOW IT WORKS</MenuItem>
        <MenuItem onClick={() => {
          window.location.href = `${domain}/testamentary-capacity`;
          handleClose();
        }}>TESTAMENTARY CAPACITY</MenuItem>
        <MenuItem onClick={() => {
          window.location.href = `${domain}/about-us`;
          handleClose();
        }}>ABOUT US</MenuItem>
        {props.signedIn && 
          <MenuItem onClick={() => {
            window.location.href = `/userAccount`;
            handleClose();
          }}>My Account</MenuItem>
        } 
        <MenuItem onClick={() => {
          props.handleSignIn();
          handleClose();
        }}>{props.signedIn ? "Log Out" : "Log In"}</MenuItem>
        </div>
      </Menu>
    </div>
  )
};

export default Header;
