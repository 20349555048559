import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';

interface DialogProps {
  open: boolean;
  close(): void;
}

const updatePopup:React.FC<DialogProps> = ({open, close}) => {
  return (
    <Dialog open={open} onClose={close} className="LogoutPopup update-success-dialog">
      <DialogTitle>
        Thank you, your details have been updated.
      </DialogTitle>
      <div className="btn-row" style={{ display: " flex", alignItems: 'center', justifyContent: 'center' }}>
        <Button
          className="save-button"
          variant="contained"
          color="primary"
          onClick={close}
        >
          Ok
        </Button>
      </div>
    </Dialog>
  );
}

export default updatePopup;
