import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CloseIcon from '@material-ui/icons/Close';

import './UpdatePaymentDetails.scss';
import { Authorization } from '../../services/authorization';

const UupdateDetails: React.FC = () => {
  const history = useHistory();
  const [isUserSubscriptionActive, setActiveSubscriotion] = React.useState(null as boolean | null);

  const checkSubscription = async () => {
    var haveSubscription = await Authorization.GetUserSubscriptionStatus();
    setActiveSubscriotion(haveSubscription);
  }

  useEffect(() => {
    checkSubscription();
  }, []);

  return (
    <div className="UpdatePaymentDetails" data-testid="UserAccount">
      <div>
        <div className="upper-container">
          <div className="ft-25 semi-bold title">On this page you can manage your account payment options</div>
        </div>
        <div className="cards-content">
          <Card variant="outlined" className="card" onClick={() => history.push('/updateCreditCard')}>
            <CardContent className="ft-22">
              <div className="card-text-block">
                <div>
                  <p>Enter new card details</p>
                </div>
              </div>
              <div className="btn-container"><CreditCardIcon /></div>
            </CardContent>
          </Card>
          {isUserSubscriptionActive &&
            <Card variant="outlined" className="card" onClick={() => history.push('/cancelSubscription')}>
              <CardContent className="ft-20">
                <div className="card-text-block">
                  <div>
                    <p>Click here to cancel</p>
                    <p>your subscription</p>
                  </div>
                </div>
                <div className="btn-container"><CloseIcon /></div>
              </CardContent>
            </Card>}
        </div>
      </div>
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Back
      </Button>
    </div>
  )
}

export default UupdateDetails;
