import axios from "axios";
import { SERVER_URL } from "../config";
import { Authorization } from "./authorization";

export const downloadCertificate = async (videoId: string) => {

  const url = SERVER_URL + "/pdf/generateCertificate/";

  var res = await axios.post(url, { videoId }, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });

  if (res && res.data) {
    fetch(res.data)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'certificate.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(e => console.error(e));
  }
}

export const downloadMentalCapacityCertificate = async (videoId: string) => {

  const url = SERVER_URL + "/pdf/generateMentalCapacityCertificate/";

  var res = await axios.post(url, { videoId }, {
    headers: {
      Authorization: await Authorization.AuthToken()
    }
  });

  if (res && res.data) {

    fetch(res.data)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'mental-capacity-report.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(e => console.error(e));
  }
}