import React, { forwardRef, useEffect } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { CircularProgress, Grid } from '@material-ui/core';
import { browserVersion, isChrome, isEdge, isFirefox, isMobile, isMobileSafari, isSafari } from 'react-device-detect';

import './DeviceChecks.scss';
import { LANDING_URL } from '../../config';
import { IGeolocation } from '../AccountCreation/AccountCreation';

interface DeviceCheckProps {
  moveNext(): void;
  setNextButtonDisabledState(value: boolean): void;
  setGeolocation(geolocation: IGeolocation): void;
}

/**
  Desktop Safari >= 14.1
  IOS Safari >= 14.5
  Chrome >= 49
  Android Chrom >= 92
  Firefox >= 29
  Mobile Firefox - not supported
  Edge >= 79
 */
const DeviceChecks = (props: DeviceCheckProps, ref: any) => {
  const domain = LANDING_URL;
  // null - loading, false - error, true - camera present
  const [isCameraGood, setCameraStatus] = React.useState(null as boolean | null);
  const [isSpeeFast, setSpeedStatus] = React.useState(null as boolean | null);
  const [isBrowserGood, setBrowserStatus] = React.useState(null as boolean | null);

  const [warningShown, setWarningShown] = React.useState(false);
  const [cameraTimeout, setCameraTimeout] = React.useState(-1);

  const [geolocationChecked, setGeolocation] = React.useState(null as boolean | null);

  const getGeolocation = async (enableHighAccuracy: boolean = true) => {
    try {
      if (navigator.geolocation) {

        const position = (await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: enableHighAccuracy
          });
        })) as any;

        if (position) {
          props.setGeolocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setGeolocation(true);
        }
      } else {
        console.log("can`t get location");
      }
    } catch (e) {
      console.log(e);
      if (enableHighAccuracy){
        console.log("can`t get high accuracy location");
        await(getGeolocation(false));
      } else {
        console.log("can`t get low accuracy location");
      }
    }
  };
  
  useEffect(() => {
    if (isCameraGood && isSpeeFast && isBrowserGood) {
      props.setNextButtonDisabledState(false);
      props.moveNext();
    } else if (isSpeeFast !== null && isCameraGood !== null) {
      setWarningShown(true);
    }

    if (isCameraGood && isSpeeFast && isBrowserGood) {
      setWarningShown(false);
    }
    // eslint-disable-next-line
  }, [isCameraGood, isSpeeFast, isBrowserGood, geolocationChecked]);

  const initialChecks = async () => {
    props.setNextButtonDisabledState(true);
    checkBrowser();
    SpeedTest();
    await getGeolocation();
    await checkCammera();
  }

  useEffect(() => {
    initialChecks();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (cameraTimeout !== -1) {
      clearTimeout(cameraTimeout);
    }
    if (!isCameraGood) {
      var timeout = setTimeout(() => {
        setCameraStatus(false);
        setWarningShown(true);
      }, 20000);
      setCameraTimeout(timeout as any);
    }
    // eslint-disable-next-line
  }, [isCameraGood]);

  const SpeedTest = async () => {
    const imageAddr = "https://media.capacityvault.co.uk/internetSpeedTestFile.jpg";
    const downloadSize = 4559227; //bytes

    const MeasureConnectionSpeed = async () => {
      let endTime = new Date().getTime();
      var download = new Image();
      download.onload = function () {
        endTime = new Date().getTime();
        showResults();
      }

      download.onerror = function (err, msg) {
        setSpeedStatus(false);
      }

      const startTime = new Date().getTime();
      var cacheBuster = "?nnn=" + startTime;
      download.src = imageAddr + cacheBuster;

      const showResults = () => {
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = downloadSize * 8;
        const speedBps = (bitsLoaded / duration);
        const speedKbps = (speedBps / 1024);
        const speedMbps = (speedKbps / 1024);
        console.log('internet speed Mbps', speedMbps);
        if (speedMbps > 10) {
          setSpeedStatus(true);
        } else {
          setSpeedStatus(false);
        }
      }
    }

    await MeasureConnectionSpeed();
  }

  const checkBrowser = () => {
    let browserStatus = false;
    if (isSafari && parseFloat(browserVersion) >= 13) {
      if (isMobileSafari) {
        if (parseFloat(browserVersion) >= 14.5) {
          browserStatus = true;
        }
      } else {
        browserStatus = true;
      }
    }

    if (isChrome && parseFloat(browserVersion) >= 49) {
      browserStatus = true;
    }

    if (isFirefox && parseFloat(browserVersion) >= 29) {
      browserStatus = true;
    }

    if (isEdge && parseFloat(browserVersion) >= 79) {
      browserStatus = true;
    }

    // phone and tablets
    if (isMobile) {
      if (isFirefox) {
        browserStatus = false;
      }
    }
    setBrowserStatus(browserStatus);
  }

  const checkCammera = async () => {

    try {
      var userMediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      userMediaStream.getTracks().forEach(function (track) {
        track.stop();
      });
      setCameraStatus(true)
      console.log('got camera');
    } catch (err) {
      console.log(err);
      setCameraStatus(false)
      setWarningShown(true);
    }
  }

  const handleDeviceChecksRetry = async () => {
    setCameraStatus(null);
    setSpeedStatus(null);
    setBrowserStatus(null);

    await SpeedTest();
    checkBrowser();
    await getGeolocation();
    await checkCammera();
  }
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid>
        <div className="DeviceChecks" data-testid="DeviceChecks">
          <div className="left-column">
            <div className="status-box">
              Checking your Internet connection
              {isSpeeFast === true ? <CheckIcon className="check-icon" /> : isSpeeFast === false ? <ClearIcon className="fail-icon" /> : <CircularProgress />}
            </div>
            <div className="status-box">
              Checking your browser
              {isBrowserGood ? <CheckIcon className="check-icon" /> : isBrowserGood === false ? <ClearIcon className="fail-icon" /> : <div />}
            </div>
            <div className="status-box">
              Checking your location
              {geolocationChecked ? <CheckIcon className="check-icon" /> : geolocationChecked === false ? <ClearIcon className="fail-icon" /> : <div />}
            </div>
            <div className="status-box">
              Checking your forward facing camera
              {isCameraGood === true ? <CheckIcon className="check-icon" /> : isCameraGood === false ? <ClearIcon className="fail-icon" /> : <CircularProgress />}
            </div>
          </div>
          {warningShown &&
            <div className="center-column">
              or
            </div>}
          {(warningShown || !isBrowserGood) &&
            <div className="right-column">
              <div className="warning-box">
                {isBrowserGood === false ? <p>your browser is not compatible – please download latest version</p> :
                  isSpeeFast === false ? <p>your internet connection is unstable – please connect to Wi-Fi or try again later</p> :
                    isCameraGood === false ? <p>We were unable to access your device. Click <a className="link" target="_blank" rel="noreferrer" href={domain + '/camera-and-microphone-troubleshoot/'}>here</a> for guidance.</p>
                      : false ? <p>we are unable to access your microphone - please ensure no other programme or application is using your microphone</p> : ''}
                {(isBrowserGood === false || isSpeeFast === false || isCameraGood === false) && <a
                  href="#" onClick={() => handleDeviceChecksRetry()}
                >Retry</a>}
              </div>
            </div>}
        </div>
      </Grid>
    </Grid>
  )
};

export default forwardRef(DeviceChecks);
