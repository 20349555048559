import { Button } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UserPassword from "../UserPassword/UserPassword";
import './ForgotPasswordSet.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ForgotPassword = () => {
  const [user] = useState({});
  const [username, setUsername] = useState("");
  const userPasswordRef = useRef(null);
  const [nextButtonDisabledState, setNextButtonDisabledState] = useState(false);
  const history = useHistory();

  let query = useQuery();
  
  const setUsernameValue = async () => {
    var email = query.get("username")
    setUsername(email ?? "");
  }

  useEffect(() => {
    setUsernameValue();
    // eslint-disable-next-line
  }, []);

  const handleNext = async () => {
    if (userPasswordRef && userPasswordRef.current) {
      const current = userPasswordRef?.current as any;
      var res = await current.next();
      if (res) {
        history.push("/login");
      }
    }
  };

  return (
    <div className="ForgotPassword">
      <div className="ft-22">
        <UserPassword user={user} setNextButtonDisabledState={setNextButtonDisabledState} ref={userPasswordRef} confirmPasswordUsername={username} />
        <Button
          disabled={nextButtonDisabledState}
          className="save-button"
          variant="contained"
          color="primary"
          onClick={() => handleNext()}
        >
          Save Password
        </Button>
      </div>
    </div>
  );
}

export default ForgotPassword;

