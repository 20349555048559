import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SetRecordingToken } from "../../services/localStorage/localstorage";
import { checkRecordingToken } from "../../services/axios/requests";

interface Params {
  token: string;
}

const RecordingToken = () => {
  const history = useHistory();
  const params = useParams<Params>();

  const [tokenInvalid, setInvalid] = useState(false);

  const checkToken = async () => {
    const {token} = params;
    const res = await checkRecordingToken(token);
    console.log(res);
    if (res && res.status === 200 && res.data) {
      // move to video recording 
      SetRecordingToken(token);
      history.push('/');
    } else {
      setInvalid(true);
    }
  }

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="RecordingToken">
      <div style={{'marginTop': '40px'}}>
        {tokenInvalid ? "Token is not valid or expired" : "Wait a minute we are checking if token is valid"}
      </div>
    </div>
  );
};

export default RecordingToken;
