import React, { useRef, useState } from 'react';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './UpdateCreditCard.scss';
import UpdateCreditCardElement from '../UpdateCreditCardElement/UpdateCreditCardElement';
import { STRIPE_PK } from '../../config';

const stripePromise = loadStripe(STRIPE_PK);

const UpdateCreditCard: React.FC = () => {
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const userNewCardRef = useRef(null);

  const handleCardChange = async () => {
    if (userNewCardRef && userNewCardRef.current) {
      const current = userNewCardRef?.current as any;
      const res = await current.update();
      if (res) {
        setSuccess(true);
      }
    }
  }

  return (
    <div>
      <Elements stripe={stripePromise}>
        <UpdateCreditCardElement ref={userNewCardRef} />
      </Elements>
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/updatePaymentDetails')}
      >
        Back
      </Button>
      <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
        className="save-button"
        variant="contained"
        color="primary"
        onClick={handleCardChange}
      >
        Save
      </Button>
      <Dialog open={success} className="LogoutPopup update-success-dialog">
        <DialogTitle>
          Payment details have been updated
        </DialogTitle>
        <div className="btn-row" style={{ display: " flex", alignItems: 'center', justifyContent: 'center' }}>
          <Button
            className="save-button"
            variant="contained"
            color="primary"
            onClick={() => history.push('/userAccount')}
          >
            Next
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default UpdateCreditCard;
