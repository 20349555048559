import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SignInState {
  value: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: SignInState = {
  value: false,
  status: 'idle',
};

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    signIn: (state) => {
      state.value = true;
    },
    signOut: (state) => {
      state.value = false;
    }
  }
});

export const { signIn, signOut } = signInSlice.actions;

export const selectSignIn = (state: RootState) => state.signIn.value;

export default signInSlice.reducer;
