import { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElement } from "@stripe/stripe-js"
import { Button, Checkbox, CircularProgress } from '@material-ui/core';

import './CheckOut.scss';
import { PURCHASE_PRICE } from '../../config';
import { Authorization } from '../../services/authorization';
import { LANDING_URL } from '../../config';

import { useAppSelector } from '../../app/hooks';
import { selectAffiliate } from '../../features/affiliate/affiliateSlice';
import LifeTimeUpdate, { LifeTimeUpdates } from '../baseComponents/LifeTimeUpdate';
import { GetRecordingToken } from '../../services/localStorage/localstorage';
import {
  checkRecordingToken, Voucher, validateVoucher, syncSubscription,
  paymentSubscription, combineVideo, getPaymentIntentSecret,
  updateUser
} from '../../services/axios/requests';

interface CheckOutProps {
  next(): void;
};

const CheckOut = (props: CheckOutProps) => {
  const username = Authorization.GetUsername();

  const [affiliateToken, setAffiliateToken] = useState(null as string | null);

  const [accepted, setAccepted] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [lifeTimeUpdates, setLifeTimeUpdates] = useState(-1);

  const [nextButtonSubmitting, setNextButtonSubmitting] = useState(false);

  const [discountApplied, setDiscountApplied] = useState("");
  const [voucherCode, setVoucherCode] = useState('');
  const [discountData, setDiscountData] = useState(PURCHASE_PRICE);
  const [voucher, setVoucher] = useState(null as Voucher | null);

  const stripe = useStripe();
  const elements = useElements();
  const affiliate = useAppSelector(selectAffiliate);

  const generateCertificate = async () => {
    try {
      const videoUniqueFolder = localStorage.getItem('videoFolder');
      await combineVideo(username + '/' + videoUniqueFolder);
    }
    catch (e) {
      console.log('error')
      console.log(JSON.stringify(e));
    }
  }

  const next = async (e: any) => {
    e.preventDefault();
    if (accepted) {
      setNextButtonSubmitting(true);

      if (isFree()) {
        // NO PAYMENT
        await generateCertificate();
        await updateUser({signupComplete: true});

        props.next();
      } else {
        const res = await handlePayment();
        setNextButtonSubmitting(false);
        if (res && res === true) {
          await updateUser({signupComplete: true});
          props.next();
        }
        return res;
      }
    } else {
      setNextClicked(true);
    }
  }

  const validateRecordingToken = async () => {
    const token = GetRecordingToken();
    if (token) {
      const res = await checkRecordingToken(token);
      if (res && res.status === 200 && res.data) {
        setAffiliateToken(token);
        setDiscountData('0');
      }
    }
  }

  useEffect(() => {
    validateRecordingToken();
    applyAffiliateDiscount();
    // eslint-disable-next-line
  }, [])

  const applyVoucher = async () => {

    const voucherRes = await validateVoucher(voucherCode);

    if (voucherRes && voucherRes.status === 200 && voucherRes.data) {
      if (voucherRes.data.active) {
        const basePurchasePrice = parseFloat(PURCHASE_PRICE);

        setDiscountApplied(voucherRes.data.isFixedPrice
          ? `(discount £${voucherRes.data.value})`
          : `(discount ${voucherRes.data.value}%)`);

        let discountedValue = voucherRes.data.isFixedPrice
          ? (basePurchasePrice - voucherRes.data.value)
          : basePurchasePrice - (basePurchasePrice * voucherRes.data.value / 100)

        if (discountedValue < 0)
          discountedValue = 0;

        setDiscountData(discountedValue.toFixed(2));
        setVoucher(voucherRes.data);
        console.log(voucherRes.data);
      }
    }
  }

  const applyAffiliateDiscount = async () => {
    if (affiliate && parseInt(affiliate.discount) > 0) {
      var basePurchasePrice = parseFloat(PURCHASE_PRICE);

      setDiscountApplied(`(discount ${affiliate.affiliateName} ${affiliate.discount}%)`);

      var discountedValue = basePurchasePrice - (basePurchasePrice * parseInt(affiliate.discount) / 100)

      if (discountedValue < 0)
        discountedValue = 0;

      setDiscountData(discountedValue.toFixed(2));
    }
  }

  const payWithoutSubscription = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const clientSecret = await getPaymentIntentSecret(voucherCode);

    if (clientSecret) {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            email: await Authorization.GetUsername(),
          },
        },
      });

      console.log('result', result)

      if (result.paymentIntent && result.paymentIntent.status === "succeeded") {
        await generateCertificate();
      } else {
        alert('There is an issue with payment.')
      }
    }
  }

  // When initial payment is not 0.00 pounds, payment should be confirmed.
  const confirmCardPayment = async (subscription: any) => {

    console.log('confirmCardPayment')

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { client_secret } = subscription.latest_invoice.payment_intent;

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {
          email: await Authorization.GetUsername(),
        },
      },
    });

    if (result.error) {
      console.log('[error]', result.error);
    } else {
      console.log('[PaymentIntent]', result.paymentIntent);
    }

    if (result.paymentIntent?.status === 'succeeded') {
      await syncSubscription();
      await generateCertificate();
      return true;
    } else {
      alert('There is an issue with payment.')
    }

    return false;
  }

  // When initial payment is 0.00 punds, only credit card setup should be confirmed.
  const confirmCardSetup = async (subscription: any) => {

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const { pending_setup_intent } = subscription;

    if (pending_setup_intent) {
      const { client_secret } = subscription.pending_setup_intent;
      var result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: elements.getElement(CardElement) as StripeCardElement,
          billing_details: {
            email: await Authorization.GetUsername(),
          },
        }
      });

      if (result.error) {
        console.log('[error]', result.error);
      } else {
        console.log('[PaymentIntent]', result.setupIntent);
      }

      if (result.setupIntent?.status === 'succeeded') {
        await syncSubscription();
        await generateCertificate();
        return true;
      }
    }

    return false;

  }

  const handlePayment = async () => {
    console.log('handlePayment')

    let subscriptionResponse = null;

    // do subscription payment
    if (lifeTimeUpdates === LifeTimeUpdates.Yes) {
      try {
        subscriptionResponse = await paymentSubscription(voucherCode);
        console.log(subscriptionResponse);
      } catch (e) {
        console.log(e);
        alert('Please try again later.')
        return;
      }

      if (subscriptionResponse.data.error) {
        alert('Please try again later.')
        return;
      }
      console.log('subscription created')
    }

    // Pay without subscription
    if (subscriptionResponse === null && lifeTimeUpdates !== LifeTimeUpdates.Yes) {
      return await payWithoutSubscription();
    }

    if (!subscriptionResponse)
      return;
    if (!subscriptionResponse.data)
      return;

    // pay after subscription
    if (subscriptionResponse && subscriptionResponse.data && subscriptionResponse.data.subscription.latest_invoice?.payment_intent?.client_secret != null) {
      return await confirmCardPayment(subscriptionResponse.data.subscription);
    } else {
      return await confirmCardSetup(subscriptionResponse.data.subscription);
    }
  };

  const isFree = () => {
    // NO PAYMENT when there is Affiliate token, voucher 100% or affiliate discount 100% without lifetime updates.
    if (lifeTimeUpdates === LifeTimeUpdates.No || lifeTimeUpdates === LifeTimeUpdates.Unset) {
      // NO PAYMENT when affiliat token is active
      if (affiliateToken)
        return true;

      // NO PAYMENT when voucher discount 100%
      if (voucher && voucher.isFixedPrice === false && voucher.value === 100)
        return true;

      // NO PAYMENT when affiliate discount 100%
      if (affiliate && parseInt(affiliate.discount) === 100)
        return true;
    }

    return false;
  }

  const renderCardRow = () => {
    if (isFree()) {
      return;
    }

    return (
      <div className="pay-card-row">
        <div className="card-box">
          <CardElement options={{ hidePostalCode: true }} />
        </div>
        <div className="desktop-inline-row">
          <img src="/PoweredbyStripe.svg" alt="PoweredbyStripe" />
          <div className="bold">for secure payments</div>
        </div>
      </div>
    );
  }

  const renderNextLabel = () => {
    return nextButtonSubmitting ? <CircularProgress /> : 'Confirm';
  }

  return (
    <form className="CheckOut" onSubmit={next}>
      <div className="bold ft-25">One more thing</div>
      <div className="label-box ft-22">
        <div className="label">Capacity Vault secure setup:</div>
        <div className="bold">£{PURCHASE_PRICE}</div>
      </div>
      <div className="label-box ft-22">
        <div className="label bold">Amount due:</div>
        <div className="bold">£{discountData} {discountApplied}</div>
      </div>
      {lifeTimeUpdates === LifeTimeUpdates.Yes &&
        <div className="label-box ft-22">
          <div className="label">Subscription year 1:</div>
          <div className="bold">£0</div>
        </div>}
      {lifeTimeUpdates === LifeTimeUpdates.Yes &&
        <div className="label-box ft-22">
          <div className="label">Subscription year 2 onwards:</div>
          <div className="bold">£10 per year</div>
        </div>}

      {/* Voucher code filed should not be available for affiliates */}
      {!affiliate && !affiliateToken &&
        <div className="flex-inline voucher-row">
          <input className="ft-18" onChange={e => setVoucherCode(e.target.value)} placeholder="voucher code?" />
          <button className="ft-18 apply-btn" onClick={e => {
            e.preventDefault();
            applyVoucher();
          }}>Apply</button>
        </div>}

      <div className={`flex-inline accept-condition-check ft-22 ${(nextClicked && !accepted) ? "invalid" : ''}`} onClick={() => setAccepted(!accepted)}>
        <Checkbox id='termsAndConditionsCheckbox' checked={accepted} />
        <div className="font-light">I agree to the <a className="blue" rel="noreferrer" href={LANDING_URL + '/terms-and-conditions'} target="_blank">terms and conditions</a></div>
      </div>

      {renderCardRow()}

      <LifeTimeUpdate lifeTimeUpdates={lifeTimeUpdates} setLifeTimeUpdates={setLifeTimeUpdates} />
      <div className="text-box ft-22">
        You can cancel this Lifetime Updates at any time. If you cancel, you will have to pay to make new entries if you update your will, and your estate will have to pay a fee to access any recordings you make.
      </div>
      <Button style={{ position: "absolute", bottom: "10vh", right: "20vw" }}
        variant="contained"
        color="primary"
        type="submit"
        disabled={nextButtonSubmitting}
        className="green-submit-button"
      >
        {renderNextLabel()}
      </Button>
    </form>
  )
};

export default CheckOut;
