import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { FC } from "react";

interface PasswordInputProps {
  id: string;
  label: string;
  name: string;
  placeholder: string;
  autoComplete: string;
  password: string | undefined;
  onChange: any;
  error: string;
}

const PasswordInput: FC<PasswordInputProps> = ({
  id,
  label,
  name,
  placeholder,
  autoComplete,
  password,
  onChange,
  error,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <FormControl variant="outlined">
      <InputLabel shrink={true} htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id={id}
        value={password}
        onChange={onChange}
        error={!!error}
        placeholder={placeholder}
        name={name}
        autoComplete={autoComplete}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />
      <FormHelperText id="outlined-weight-helper-text">{error}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
