import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import './UpdateDetails.scss';
import UpdateUserDetails from '../UserDetails/UpdateUserDetails';
import { getCurrentUser } from '../../services/axios/requests';

const UupdateDetails: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = React.useState(null as any);

  const getUser = async () => {
    try {
      const response = await getCurrentUser();
      
      if (response && response.status === 200) {
        setUser(response.data)
      }
    } catch {
      console.log('can`t get current user');
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="UserDetails UpdateUserDetailsPage">
      {user && <UpdateUserDetails user={user} />}
      <Button style={{ position: "absolute", bottom: "10vh", left: "20vw" }}
        className="back-button"
        variant="contained"
        color="primary"
        onClick={() => history.push('/userAccount')}
      >
        Back
      </Button>
    </div>
  )
}

export default UupdateDetails;
