import { Button } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import UserPassword from "../UserPassword/UserPassword";
import './SetPassword.scss';
import { Authorization } from "../../services/authorization";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SetPassword = () => {
  const [user, setUser] = useState({});
  const userPasswordRef = useRef(null);
  const [nextButtonDisabledState, setNextButtonDisabledState] = useState(false);
  const history = useHistory();

  let query = useQuery();
  
  const setUsernameValue = async () => {
    const email = query.get("username") ?? "";
    setUser({email});

    if (await Authorization.Exists(email)) {
      history.push("/login");
      return;
    }
  }

  useEffect(() => {
    setUsernameValue();
    // eslint-disable-next-line
  }, []);

  const handleNext = async () => {
    if (userPasswordRef && userPasswordRef.current) {
      const current = userPasswordRef?.current as any;
      await current.next();
      history.push("/login");
    }
  };

  return (
    <div className="SetPassword">
      <div className="ft-22">
       <UserPassword user={user} setNextButtonDisabledState={setNextButtonDisabledState} ref={userPasswordRef} />
        <Button
          disabled={nextButtonDisabledState}
          className="save-button"
          variant="contained"
          color="primary"
          onClick={() => handleNext()}
        >
          Save Password
        </Button>
      </div>
    </div>
  );
}

export default SetPassword;

