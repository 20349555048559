import './ToggleButton.scss';

interface ToogleProps {
  firstValue: number;
  secondValue: number;
  currentValue?: number;
  update(value: number): void;
}

const ToogleButton = (props: ToogleProps) => {
  return (
    <div className="toggle-button-container-r4">
      <button type="button"
        className={"toggle-button-r4 first-btn-r4 " + (props.currentValue === props.firstValue ? "active" : "")}
        onClick={() => props.update(props.firstValue)}
      >
        Yes
      </button>
      <button type="button"
        className={"toggle-button-r4 " + (props.currentValue === props.secondValue ? "active" : "")}
        onClick={() => props.update(props.secondValue)}>
          No
        </button>
    </div>
  );
};

export default ToogleButton;
